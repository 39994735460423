<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="lang=='English'?navFixed2:navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <div class="lang-box">
      <el-radio-group v-model="lang" size="medium" @change="goHref">
        <el-radio-button label="中文"></el-radio-button>
        <el-radio-button label="English"></el-radio-button>
      </el-radio-group>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <whiteBookFile_enUS v-if="lang=='English'" :scrollContainer="scrollContainer"></whiteBookFile_enUS>
      <whiteBookFile_zhCN v-else :scrollContainer="scrollContainer"></whiteBookFile_zhCN>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
import whiteBookFile_zhCN from "@/components/documentsBase/template/whiteBookFile_zhCN";
import whiteBookFile_enUS from "@/components/documentsBase/template/whiteBookFile_enUS";
export default {
  components: { Nav,whiteBookFile_zhCN,whiteBookFile_enUS},
  metaInfo: {
    title: "ITGSA | 移动智能终端生态联盟 | 地震预警服务规范",
    meta: [
      {
        name: "keywords",
        content: "ITGSA,生态联盟,金标认证,地震预警服务规范",
      },
      {
        name: "description",
        content:
          "Android应用的稳定性是构建Android应用体验的重要一环，直接影响用户体验，本标准旨在构建一套Android应用稳定性测试衡量方法，牵引生态内所有应用的稳定性质量改进，构建共同的稳定和体验良好的应用生态。",
      },
    ],
  },
  data() {
    return {
      scrollContainer: HTMLElement,
      lang: this.$route.query.lang?this.$route.query.lang:'中文',
      navFixed: [
          {
            name: "一、 简介",
            isActive:0,
            class:'#1',
            child:[
              {
                name: "1.1 设备屏幕形态",
                isActive:null,
                class:'#1-1',
              },
              {
                name: "1.2 折叠屏设备使用姿态",
                isActive:null,
                class:'#1-2',
              },
            ]
          },
          {
            name: "二、 适配原则",
            isActive:null,
            class:'#2',
            child:[
              {
                name: "2.1 体验延续",
                isActive:null,
                class:'#2-1',
              },
              {
                name: "2.2 体验提升",
                isActive:null,
                class:'#2-2',
              },
            ]
          },
          {
            name: "三、 设计指南",
            isActive:null,
            class:'#3',
            child:[
              {
                name: "3.1 适配方法",
                isActive:null,
                class:'#3-1',
                child:[
                  {
                    name: "3.1.1 调整显示大小",
                    isActive:null,
                    class:'#3-1-1',
                    child:[
                      {
                        name: "3.1.1.1 完整显示",
                        isActive:null,
                        class:'#3-1-1-1',
                      },
                      {
                        name: "3.1.1.2 使用放大倍率进行适配",
                        isActive:null,
                        class:'#3-1-1-2',
                      },
                      {
                        name: "3.1.1.3 图片、视频等元素的显示大小",
                        isActive:null,
                        class:'#3-1-1-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.2 响应式布局与基础适配",
                    isActive:null,
                    class:'#3-1-2',
                    child:[
                      {
                        name: "3.1.2.1 定义",
                        isActive:null,
                        class:'#3-1-2-1',
                      },
                      {
                        name: "3.1.2.2 响应式布局的基本类型",
                        isActive:null,
                        class:'#3-1-2-2',
                      },
                      {
                        name: "3.1.2.3  检查所有内容的响应变化能力",
                        isActive:null,
                        class:'#3-1-2-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.3 断点与自适应布局",
                    isActive:null,
                    class:'#3-1-3',
                    child:[
                      {
                        name: "3.1.3.1 断点与栅格",
                        isActive:null,
                        class:'#3-1-3-1',
                      },
                      {
                        name: "3.1.3.2 断点自适应布局的基本类型",
                        isActive:null,
                        class:'#3-1-3-2',
                      },
                      {
                        name: "3.1.3.3  深度定制布局",
                        isActive:null,
                        class:'#3-1-3-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.4 优化交互体验",
                    isActive:null,
                    class:'#3-1-4',
                    child:[
                      {
                        name: "3.1.4.1 为折叠屏优化",
                        isActive:null,
                        class:'#3-1-4-1',
                      },
                      {
                        name: "3.1.4.2 为平板优化",
                        isActive:null,
                        class:'#3-1-4-2',
                      },
                      {
                        name: "3.1.4.3  为大屏幕优化界面与组件",
                        isActive:null,
                        class:'#3-1-4-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.5 多任务与多窗口",
                    isActive:null,
                    class:'#3-1-5',
                    child:[
                      {
                        name: "3.1.5.1 分屏",
                        isActive:null,
                        class:'#3-1-5-1',
                      },
                      {
                        name: "3.1.5.2 悬浮窗",
                        isActive:null,
                        class:'#3-1-5-2',
                      },
                      {
                        name: "3.1.5.3 平行视窗/应用多窗/平行窗口",
                        isActive:null,
                        class:'#3-1-5-3',
                      },
                      {
                        name: "3.1.5.4 跨应用操作",
                        isActive:null,
                        class:'#3-1-5-4',
                      },
                    ],
                  },
                ],
              },
              {
                name: "3.2 场景适配案例",
                isActive:null,
                class:'#3-2',
                child:[
                  {
                    name: "3.2.1 首页类场景",
                    isActive:null,
                    class:'#3-2-1',
                  },
                  {
                    name: "3.2.2 影音娱乐类场景",
                    isActive:null,
                    class:'#3-2-2',
                  },
                  {
                    name: "3.2.3 通讯类场景",
                    isActive:null,
                    class:'#3-2-3',
                  },
                  {
                    name: "3.2.4 论坛社区类场景",
                    isActive:null,
                    class:'#3-2-4',
                  },
                  {
                    name: "3.2.5 资讯类场景",
                    isActive:null,
                    class:'#3-2-5',
                  },
                  {
                    name: "3.2.6 游戏类场景",
                    isActive:null,
                    class:'#3-2-6',
                  },
                ],
              },
            ],
          },
          {
            name: "四、 开发指导",
            isActive:null,
            class:'#4',
            child:[
              {
                name: "4.1 屏幕兼容性",
                isActive:null,
                class:'#4-1',
                child:[
                  {
                    name: "4.1.1 应用resizeable能力支持",
                    isActive:null,
                    class:'#4-1-1',
                  },
                  {
                    name: "4.1.2 应用布局优化",
                    isActive:null,
                    class:'#4-1-2',
                  },
                ],
              },
              {
                name: "4.2 应用连续性",
                isActive:null,
                class:'#4-2',
                child:[
                  {
                    name: "4.2.1 正确使用应用资源",
                    isActive:null,
                    class:'#4-2-1',
                  },
                  {
                    name: "4.2.2 动态支持横竖屏切换",
                    isActive:null,
                    class:'#4-2-2',
                  },
                ],
              },
              {
                name: "4.3 多屏多窗口",
                isActive:null,
                class:'#4-3',
                child:[
                  {
                    name: "4.3.1 支持分屏和悬浮窗模式",
                    isActive:null,
                    class:'#4-3-1',
                  },
                  {
                    name: "4.3.2 多项恢复",
                    isActive:null,
                    class:'#4-3-2',
                  },
                  {
                    name: "4.3.3 专属资源访问",
                    isActive:null,
                    class:'#4-3-3',
                  },
                  {
                    name: "4.3.4 多窗口下请求方向",
                    isActive:null,
                    class:'#4-3-4',
                  },
                  {
                    name: "4.3.5 多显示屏",
                    isActive:null,
                    class:'#4-3-5',
                  },
                ],
              },
              {
                name: "4.4 折叠屏适配",
                isActive:null,
                class:'#4-4',
                child:[
                  {
                    name: "4.4.1 折叠屏分类",
                    isActive:null,
                    class:'#4-4-1',
                  },
                  {
                    name: "4.4.2 两类折叠屏差异",
                    isActive:null,
                    class:'#4-4-2',
                  },
                  {
                    name: "4.4.3 折叠屏适配建议",
                    isActive:null,
                    class:'#4-4-3',
                  },
                  {
                    name: "4.4.4 折叠屏状态监听和获取",
                    isActive:null,
                    class:'#4-4-4',
                  },
                ],
              },
              {
                name: "4.5 Activity Embedding",
                isActive:null,
                class:'#4-5',
              },
              {
                name: "4.6 WebView页面屏幕适配",
                isActive:null,
                class:'#4-6',
              },
              {
                name: "4.7 FAQ",
                isActive:null,
                class:'#4-7',
                child:[
                  {
                    name: "4.7.1 如何适配屏幕挖孔？",
                    isActive:null,
                    class:'#4-7-1',
                  },
                  {
                    name: "4.7.2 如何识别厂商折叠屏设备？",
                    isActive:null,
                    class:'#4-7-2',
                  },
                  {
                    name: "4.7.3 如何识别厂商平板设备？",
                    isActive:null,
                    class:'#4-7-3',
                  },
                  {
                    name: "4.7.4 如何识别折叠屏悬停状态？",
                    isActive:null,
                    class:'#4-7-4',
                  },
                  {
                    name: "4.7.5 当应用未接入AndroidX库时，如何感知折叠屏悬停状态？",
                    isActive:null,
                    class:'#4-7-5',
                  },
                  {
                    name: "4.7.6  如何同时兼容Activity Embedding与厂商自研平行视窗？",
                    isActive:null,
                    class:'#4-7-6',
                  },
                ]
              },
            ],
          },
          {
            name: "五、 开发、调试工具",
            isActive:null,
            class:'#5',
            child:[
              {
                name: "5.1 通过模拟器调试",
                isActive:null,
                class:'#5-1',
              },
              {
                name: "5.2 对于不同分辨率应单独调试",
                isActive:null,
                class:'#5-2',
              },
              {
                name: "5.3 测试用例建议",
                isActive:null,
                class:'#5-3',
                child:[
                  {
                    name: "5.3.1 用例1",
                    isActive:null,
                    class:'#5-3-1',
                  },
                  {
                    name: "5.3.2 用例2",
                    isActive:null,
                    class:'#5-3-2',
                  },
                  {
                    name: "5.3.3 用例3",
                    isActive:null,
                    class:'#5-3-3',
                  },
                  {
                    name: "5.3.4 用例4",
                    isActive:null,
                    class:'#5-3-4',
                  },
                  {
                    name: "5.3.5 用例5",
                    isActive:null,
                    class:'#5-3-5',
                  },
                ],
              },
            ],
          },
          {
            name: "六、 附录",
            isActive:null,
            class:'#6',
            child:[
              {
                name: "6.1 厂商产品规格介绍",
                isActive:null,
                class:'#6-1',
                child:[
                  {
                    name: "6.1.1 折叠手机",
                    isActive:null,
                    class:'#6-1-1',
                  },
                  {
                    name: "6.1.2 平板",
                    isActive:null,
                    class:'#6-1-2',
                  },
                ]
              },
              {
                name: "6.2 厂商适配指导",
                isActive:null,
                class:'#6-2',
              },
            ]
          },
      ],
      navFixed2: [
          {
            name: "I. Introduction",
            isActive:0,
            class:'#1',
            child:[
              {
                name: "1.1 Shape of device screen",
                isActive:null,
                class:'#1-1',
              },
              {
                name: "1.2 Use posture of foldable screen devices",
                isActive:null,
                class:'#1-2',
              },
            ]
          },
          {
            name: "II. Adaptation principles",
            isActive:null,
            class:'#2',
            child:[
              {
                name: "2.1 Experience continuity",
                isActive:null,
                class:'#2-1',
              },
              {
                name: "2.2 Experience improvement",
                isActive:null,
                class:'#2-2',
              },
            ]
          },
          {
            name: "III. Design Guidelines",
            isActive:null,
            class:'#3',
            child:[
              {
                name: "3.1 Adaptation method",
                isActive:null,
                class:'#3-1',
                child:[
                  {
                    name: "3.1.1 Adjustment of display size",
                    isActive:null,
                    class:'#3-1-1',
                    child:[
                      {
                        name: "3.1.1.1 Integrity of display",
                        isActive:null,
                        class:'#3-1-1-1',
                      },
                      {
                        name: "3.1.1.2 Adaptation with magnification",
                        isActive:null,
                        class:'#3-1-1-2',
                      },
                      {
                        name: "3.1.1.3 Display size of pictures, videos and other elements",
                        isActive:null,
                        class:'#3-1-1-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.2 Responsive layout and foundation adaptation",
                    isActive:null,
                    class:'#3-1-2',
                    child:[
                      {
                        name: "3.1.2.1 Definition",
                        isActive:null,
                        class:'#3-1-2-1',
                      },
                      {
                        name: "3.1.2.2 Basic types of responsive layout",
                        isActive:null,
                        class:'#3-1-2-2',
                      },
                      {
                        name: "3.1.2.3  Check responsiveness of all contents",
                        isActive:null,
                        class:'#3-1-2-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.3 Breakpoint and adaptive layout",
                    isActive:null,
                    class:'#3-1-3',
                    child:[
                      {
                        name: "3.1.3.1 Breakpoint and grid",
                        isActive:null,
                        class:'#3-1-3-1',
                      },
                      {
                        name: "3.1.3.2 Basic types of breakpoint adaptive layouts",
                        isActive:null,
                        class:'#3-1-3-2',
                      },
                      {
                        name: "3.1.3.3  Deep customization layout",
                        isActive:null,
                        class:'#3-1-3-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.4 Optimal interactive experience",
                    isActive:null,
                    class:'#3-1-4',
                    child:[
                      {
                        name: "3.1.4.1 Optimization for foldable screen",
                        isActive:null,
                        class:'#3-1-4-1',
                      },
                      {
                        name: "3.1.4.2 Optimization for pad",
                        isActive:null,
                        class:'#3-1-4-2',
                      },
                      {
                        name: "3.1.4.3  Optimize interface and components for expanded screen",
                        isActive:null,
                        class:'#3-1-4-3',
                      },
                    ],
                  },
                  {
                    name: "3.1.5 Multi-task and multi-window",
                    isActive:null,
                    class:'#3-1-5',
                    child:[
                      {
                        name: "3.1.5.1 Split screen",
                        isActive:null,
                        class:'#3-1-5-1',
                      },
                      {
                        name: "3.1.5.2 Floating window",
                        isActive:null,
                        class:'#3-1-5-2',
                      },
                      {
                        name: "3.1.5.3 Parallel viewport/APPP multi-window/parallel window",
                        isActive:null,
                        class:'#3-1-5-3',
                      },
                      {
                        name: "3.1.5.4 Cross-APP operation",
                        isActive:null,
                        class:'#3-1-5-4',
                      },
                    ],
                  },
                ],
              },
              {
                name: "3.2 Scenario adaptation cases",
                isActive:null,
                class:'#3-2',
                child:[
                  {
                    name: "3.2.1 Homepage scenarios",
                    isActive:null,
                    class:'#3-2-1',
                  },
                  {
                    name: "3.2.2 Audio /visual entertainment scenarios",
                    isActive:null,
                    class:'#3-2-2',
                  },
                  {
                    name: "3.2.3 Communication scenarios",
                    isActive:null,
                    class:'#3-2-3',
                  },
                  {
                    name: "3.2.4 Forum/community scenarios",
                    isActive:null,
                    class:'#3-2-4',
                  },
                  {
                    name: "3.2.5 Information scenarios",
                    isActive:null,
                    class:'#3-2-5',
                  },
                  {
                    name: "3.2.6 Game scenarios",
                    isActive:null,
                    class:'#3-2-6',
                  },
                ],
              },
            ],
          },
          {
            name: "IV. Development guidance",
            isActive:null,
            class:'#4',
            child:[
              {
                name: "4.1  Screen compatibility",
                isActive:null,
                class:'#4-1',
                child:[
                  {
                    name: "4.1.1 App resizable capability support",
                    isActive:null,
                    class:'#4-1-1',
                  },
                  {
                    name: "4.1.2 Application layout optimization",
                    isActive:null,
                    class:'#4-1-2',
                  },
                ],
              },
              {
                name: "4.2 Application continuity",
                isActive:null,
                class:'#4-2',
                child:[
                  {
                    name: "4.2.1  Proper utilization of application resources",
                    isActive:null,
                    class:'#4-2-1',
                  },
                  {
                    name: "4.2.2 Dynamic support for landscape screen and portrait screen switching",
                    isActive:null,
                    class:'#4-2-2',
                  },
                ],
              },
              {
                name: "4.3 Multi screen and multi window",
                isActive:null,
                class:'#4-3',
                child:[
                  {
                    name: "4.3.1 Support split screen and floating window mode",
                    isActive:null,
                    class:'#4-3-1',
                  },
                  {
                    name: "4.3.2 Multi-resume",
                    isActive:null,
                    class:'#4-3-2',
                  },
                  {
                    name: "4.3.3 Access to exclusive resources",
                    isActive:null,
                    class:'#4-3-3',
                  },
                  {
                    name: "4.3.4 Requested orientation in multi-window mode",
                    isActive:null,
                    class:'#4-3-4',
                  },
                  {
                    name: "4.3.5 Multi-display scenarios",
                    isActive:null,
                    class:'#4-3-5',
                  },
                ],
              },
              {
                name: "4.4 Foldable screen adaptation",
                isActive:null,
                class:'#4-4',
                child:[
                  {
                    name: "4.4.1 Classification of foldable screens ",
                    isActive:null,
                    class:'#4-4-1',
                  },
                  {
                    name: "4.4.2 Differences between two categories of foldable screens",
                    isActive:null,
                    class:'#4-4-2',
                  },
                  {
                    name: "4.4.3 Suggestions on foldable screen adaptation",
                    isActive:null,
                    class:'#4-4-3',
                  },
                  {
                    name: "4.4.4 Foldable screen state listen and acquisition",
                    isActive:null,
                    class:'#4-4-4',
                  },
                ],
              },
              {
                name: "4.5 Activity Embedding",
                isActive:null,
                class:'#4-5',
              },
              {
                name: "4.6 WebView page screen adaptation",
                isActive:null,
                class:'#4-6',
              },
              {
                name: "4.7 FAQ",
                isActive:null,
                class:'#4-7',
                child:[
                  {
                    name: "4.7.1 How to adapt screen hole?",
                    isActive:null,
                    class:'#4-7-1',
                  },
                  {
                    name: "4.7.2 How to identify a manufacturer's foldable-screen device?",
                    isActive:null,
                    class:'#4-7-2',
                  },
                  {
                    name: "4.7.3 How to identify a manufacturer's tablet device?",
                    isActive:null,
                    class:'#4-7-3',
                  },
                  {
                    name: "4.7.4 How to identify hover state of foldable screen?",
                    isActive:null,
                    class:'#4-7-4',
                  },
                  {
                    name: "4.7.5 How to perceive hover state of foldable screen when the APP is not connected to Android X library?",
                    isActive:null,
                    class:'#4-7-5',
                  },
                  {
                    name: "4.7.6   How to be compatible with both Activity Embedding and parallel viewport developed by the manufacturer?",
                    isActive:null,
                    class:'#4-7-6',
                  },
                ]
              },
            ],
          },
          {
            name: "V. Development and debugging tools",
            isActive:null,
            class:'#5',
            child:[
              {
                name: "5.1 Debugging with simulator",
                isActive:null,
                class:'#5-1',
              },
              {
                name: "5.2 Separate debugging for different resolutions",
                isActive:null,
                class:'#5-2',
              },
              {
                name: "5.3 Suggestions for test instances",
                isActive:null,
                class:'#5-3',
                child:[
                  {
                    name: "5.3.1  Instance 1",
                    isActive:null,
                    class:'#5-3-1',
                  },
                  {
                    name: "5.3.2  Instance 2",
                    isActive:null,
                    class:'#5-3-2',
                  },
                  {
                    name: "5.3.3  Instance 3",
                    isActive:null,
                    class:'#5-3-3',
                  },
                  {
                    name: "5.3.4  Instance 4",
                    isActive:null,
                    class:'#5-3-4',
                  },
                  {
                    name: "5.3.5  Instance 5",
                    isActive:null,
                    class:'#5-3-5',
                  },
                ],
              },
            ],
          },
          {
            name: "VI. Appendix",
            isActive:null,
            class:'#6',
            child:[
              {
                name: "6.1 Introduction to manufacturers' product specifications",
                isActive:null,
                class:'#6-1',
                child:[
                  {
                    name: "6.1.1 Foldable mobile phone",
                    isActive:null,
                    class:'#6-1-1',
                  },
                  {
                    name: "6.1.2 Tablet",
                    isActive:null,
                    class:'#6-1-2',
                  },
                ]
              },
              {
                name: "6.2 Manufacturer adaptation guidance",
                isActive:null,
                class:'#6-2',
              },
            ]
          },
      ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    goHref(value){
      this.$router.push({ path:'/documentsBase/whiteBookFile',query:{lang:value}});
    },
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">

/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .lang-box{
    position: absolute;
    right: 0;
    top: -45px;
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
    .page-box{
      .view-box{
        padding: 10px;
        .w400, .w750{
          width: 100% !important;
        }
        .p{
          padding-left: 1rem !important;
          word-break:break-all;
        }
      }
    }
}
</style>
