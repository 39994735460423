<template>
  <div class="view-box">
    <h1 class="h1 text-center ">
      ITGSA大屏设备应用适配白皮书
    </h1>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="1">
      一、简介
    </h2>
    <p class="p margin-b10">
      本文是 ITGSA
      针对手机应用在折叠屏、平板等大屏的适配指南。包括显示适配、界面布局指南、开发指导等内容，用于引导设计师与开发者快速将自己的应用适配到大屏设备上，并在OPPO、vivo、小米等多品牌大屏设备上保持一致而良好的使用体验。
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-1">
      1.1 设备屏幕形态
    </h3>
    <p class="p margin-b10 padding-l30">
      <b>● 直屏手机：</b>目前最常见的手机形态，具有一块形态固定的屏幕。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 折叠屏手机：</b
      >基于柔性屏幕技术打造，支持折叠与变化形态的手机。通常具有内外两块屏幕与多种使用姿态。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 平板设备：</b>具有较大尺寸屏幕的移动终端，屏幕尺寸通常在 7 英寸以上。
    </p>
    <p class="margin-b10">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image1.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image1.png',
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-2">
      1.2 折叠屏设备使用姿态
    </h3>
    <p class="p margin-b10">
      与普通直屏手机不同，折叠屏设备采用柔性屏幕，并支持机身与屏幕的弯曲与折叠，因此具有更多的使用姿态。通常来讲，折叠屏设备的使用姿态有三种：
    </p>
    <p class="margin-b10">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image2.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image2.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 折叠态：</b>折起后的形态，屏幕尺寸适中，使用体验与普通的直屏手机相似。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 展开态：</b
      >机身完全展开后的形态，屏幕尺寸较大，使用体验接近平板设备。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 半折态：</b
      >又称悬停态，屏幕未完全展开的形态，既可以如书本般竖立，也可以如同笔记本电脑一样的平稳放置，悬停显示内容。
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="2">
      二、 适配原则
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-1">
      2.1 体验延续
    </h3>
    <p class="p margin-b10 padding-l30">
      <b>● 跨设备体验延续 ：</b>
    </p>
    <p class="p margin-b10 padding-l30">
      用户可能同时拥有多种不同的设备。即使是同一设备，比如折叠屏手机，也可能拥有多个不同尺寸的屏幕。同一应用在不同的屏幕上运行时，界面布局、设计风格应当有所关联与延续，以带给用户一致的认知与相似的使用体验。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 跨屏幕运行连续 ：</b>
    </p>
    <p class="p margin-b10 padding-l30">
      折叠屏手机具备多种不同使用姿态，用户也会经常在这些姿态之间切换。在显示方向、屏幕大小等发生变化的时候，应用应当能够随之实时改变布局，并保留用户的浏览位置或应用状态，让用户能够继续进行之前的工作。
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-2">
      2.2 体验提升
    </h3>
    <p class="p margin-b10">
      在更大的屏幕上，用户应当获得一些体验提升。通常来讲，这种提升主要体现在以下方面：
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 更多内容：</b
      >利用更大的显示空间，应用可以呈现更多的内容，提升用户获取信息的效率，并减少翻页或滚动查看的频率。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 更佳沉浸：</b
      >对于图片、视频、游戏等内容，应用可以利用屏幕尺寸的优势，将部分内容显示得更大、更清晰，让用户在浏览时具有更强的沉浸感。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● 更高效率：</b
      >屏幕空间充足时，应用应当充分利用多出来的空间，进一步提高用户的使用效率。比如可以在当前页面内展示下一层级页面的内容，降低用户在页面间来回跳转的频率；或在多窗口状态下支持跨应用拖放，让用户可以将内容更快捷地分享到其他应用。
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="3">
      三、 设计指南
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="3-1">
      3.1 适配方法
    </h3>
    <p class="p margin-b10">
      用户拥有的移动设备来自多种品牌，屏幕的尺寸与比例也各不相同。为了让应用可以通过一次适配，在所有设备上提供舒适、一致、便捷的体验，我们推荐开发者按照以下步骤进行设计：
    </p>
    <p class="p margin-b10 padding-l30">
      1） 将界面元素显示为适当的大小。
      <a  class="a" href="#3-1-1">
        （参见 3.1.1 调整显示大小）
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      2） 构建响应式的应用界面。
      <a  class="a" href="#3-1-2">
        （参见 3.1.2 响应式布局与基础适配）
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      3） 为不同尺寸的屏幕设计不同的布局。
      <a  class="a" href="#3-1-3">
        （参见 3.1.3 断点与自适应布局）
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      4） 进一步优化大屏设备上的交互体验。
      <a  class="a" href="#3-1-4">
        （参见 3.1.4 优化交互体验）
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      5） 支持多任务场景
      <a  class="a" href="#3-1-5">
        （参见 3.1.5 多任务与多窗口）
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b10 nav-title" id="3-1-1">
      3.1.1 调整显示大小
    </h4>
    <p class="p margin-b20">
      由于屏幕比例与尺寸差异较大，将直屏手机的界面直接放大显示在折叠屏、平板设备上，通常会导致内容尺寸过大，用户体验降低，甚至可能影响部分界面的正常使用。适配时，请先按照以下原则与指导，调整内容的显示大小。
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-1-1">
      3.1.1.1 完整显示
    </h5>
    <p class="p margin-b10">
      任何情况下，应用的所有界面都应该完整可用。在大屏幕上运行时，请确保您的应用至少符合以下基本要求：
    </p>
    <p class="p margin-b10 padding-l30">
      ● 界面正常填满屏幕，内容不会拉伸变形，也不会被裁切；
    </p>
    <p class="p margin-b10 padding-l30">
      ● 图片、视频、推广弹窗等内容可以在屏幕内完整显示；
    </p>
    <p class="p margin-b10 padding-l30">
      ● 文字内容可以正常阅读，不会相互重叠；
    </p>
    <p class="p margin-b20 padding-l30">
      ● 所有功能按钮始终可以正常操作。
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-1-2">
      3.1.1.2 使用放大倍率进行适配
    </h5>
    <p class="p margin-b20">
      不同设备的屏幕具有不同的像素密度，适配时不宜按照屏幕尺寸或像素宽度对界面进行缩放。为了保证应用的界面在所有设备上舒适易用，避免针对新设备反复适配，我们推荐使用放大倍率来确认各种元素适宜的显示尺寸
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      放大倍率
    </h5>
    <p class="p margin-b10 padding-l30">
      放大倍率是指各种界面元素在当前屏幕上相对于基准设计尺寸的放大倍数，是对应屏幕的固有显示参数，其数值仅受系统「显示大小」选项影响。我们为每块屏幕都设置了适宜的放大倍率，在该倍率下，应用的元素在当前屏幕上会显示为合理的尺寸，保证舒适的使用体验。
    </p>
    <p class="p margin-b10 padding-l30">
      您可以通过以下方式获得当前屏幕的放大倍率：
    </p>
    <div class="code margin-b10 margin-l30">
      <p class="p ">float density = getResources().getDisplayMetrics().density</p>  
    </div>
    <p class="p margin-b20 padding-l30">
      在折叠屏合起/展开的过程中，屏幕的切换可能导致放大倍率产生变化，我们推荐开发者在刷新界面布局时始终获取当前的放大倍率。
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      使用放大倍率进行适配
    </h5>
    <p class="p margin-b20 padding-l30">
      放大倍率是相对于基准设计尺寸的放大值。您可以在基准设计宽度 360dp
      的画布上绘制应用界面，确定视觉元素的基准设计尺寸。在显示时直接乘以获取到的放大倍率值。
    </p>
    <p class="p margin-b20 padding-l30">
      如果您已经在其他尺寸的画布上完成了设计，请将您的应用界面等比缩放到 360dp
      的宽度，换算出各元素的基准设计尺寸，再乘以获得的放大倍率值。您可以通过下方界面中的圆形按钮观察到这个过程中显示大小的变化。
    </p>
    <p class="p margin-b20 padding-l30">
      使用这种方式进行适配后，应用无须再针对新增设备的屏幕调整界面元素的显示大小。一般来讲，应用仅需对固定的尺寸、间距、边距、字号等数值进行此换算。以比例写定的尺寸或响应式填充的规则仍然保持不变。
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image4.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image4.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      请同时检查您嵌入的 HTML5 内容，并按照同样的方式进行调整。
    </p>
    <p class="p-small margin-b20 padding-l30">
      <b
        >如果您的应用无法使用放大倍率进行适配，请尽量使各种元素在不同屏幕上保持一致的显示尺寸。图标与字体在大屏幕上不应出现过于明显的尺寸变化。</b
      >
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-1-3">
      3.1.1.3 图片、视频等元素的显示大小
    </h5>
    <p class="p margin-b20">
      图片、视频具有固定的长宽比，根据类型与显示方式的不同，我们推荐在不同的场景下使用不同的适配逻辑：
    </p>
    <p class="p margin-b10 padding-l30">
      1）展示启动图时，请不要横向拉伸直屏手机的图片资源。建议统一换用更大尺寸的图片，在任何情况下保持填满屏幕。
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image5.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image5.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      2）如果图片以宫格或瀑布流的方式显示，在较大的屏幕上，需要通过增加显示列数的方式控制元素的显示大小。避免出现屏幕变大，呈现的信息反而减少的情况。
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image6.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image6.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      3）在内容流中，如果图片、视频内容不受卡片或容器限制，建议使用放大倍率确认显示尺寸，或始终令其保持与直屏手机相近的显示大小，保证一屏内的信息总量不降低。
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image7.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image7.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      4）对于水平填满屏幕的 Banner
      元素，建议分离背景与内容，在同样的高度下仅对背景元素进行放大。内容元素使用放大倍率适配，或保持原有大小不变。
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image8.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image8.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      5）支持水平滚动切换的轮播图片，建议保持与直屏手机上相同的高度，在左右空白处增加显示上下几张图片，填满多出来的屏幕空间。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image9.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image9.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-1-2">
      3.1.2 响应式布局与基础适配
    </h4>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-2-1">
      3.1.2.1 定义
    </h5>
    <p class="p margin-b10">
      动态响应式布局（Responsive
      Layout），是指让页面元素根据屏幕尺寸的变化实时调整位置，像水一样动态适配不同大小的屏幕。将界面调整为响应式布局，您的应用可以在所有设备上自动实现基础适配。
    </p>
    <p class="p margin-b10">
      您可以通过设置约束（Constraints）的方式来构建动态响应式布局。比如将按钮设置为“距离屏幕底部
      40dp
      居中”，在任何尺寸的屏幕上，按钮都会保持这样的对齐规则。约束既可以以屏幕边缘为基准，也可以以容器边缘或其他元素边界为基准。
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-2-2">
      3.1.2.2 响应式布局的基本类型
    </h5>
    <p class="p margin-b10">
      常见的动态响应式布局包括：相对固定，相对缩放，相对拉伸，内容延伸。您可以按照这些方式设置界面元素之间的约束。
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      相对固定
    </h5>
    <p class="p margin-b10 padding-l30">
      相对固定指元素位置通过对比参照物确定，如屏幕边缘、窗口边缘或另一元素。当屏幕尺寸变化时，元素的位置保持相对固定。
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>适用场景：</b>悬浮按钮、底部导航栏、标题栏、分页 TAB、头像等。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image10.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image10.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      相对拉伸
    </h5>
    <p class="p margin-b10 padding-l30">
      相对拉伸一般指页面内元素高度固定，宽度通过对比参照物确定。页面变宽时，元素的宽度或多个元素之间的间距随之变宽。
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>适用场景：</b>文字内容、搜索框、输入框、卡片容器等。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image11.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image11.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      相对缩放
    </h5>
    <p class="p margin-b10 padding-l30">
      相对缩放指页面内元素保持固定比例，通过对比参照物确定尺寸。屏幕变宽时，元素会随之等比变大。
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>适用场景：</b
      >仅适用于全屏、沉浸浏览状态的媒体，或卡片容器中的图片、视频等内容。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image12.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image12.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      内容延伸
    </h5>
    <p class="p margin-b10 padding-l30">
      内容延伸指当屏幕变宽时，像展开画卷一样，露出数量更多的元素。
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>适用场景：</b>尺寸、间距固定的横向元素组，一般支持左右滑动。如资讯
      TAB、内容推荐卡片、可视化的数据块等。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image13.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image13.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-2-3">
      3.1.2.3 检查所有内容的响应变化能力
    </h5>
    <p class="p margin-b10">
      您可能在部分页面中嵌入了 HTML5 的内容，或整体使用了 HTML5 的页面。
    </p>
    <p class="p margin-b10">
      在适配的过程中，除了调整应用内的页面，请同时对嵌入的内容同时进行检查，以保证应用完整实现响应式变化。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image14.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image14.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4  margin-t20 margin-b10 nav-title" id="3-1-3">
      3.1.3 断点与自适应布局
    </h4>
    <p class="p margin-b10">
      断点自适应布局 （Adaptive
      Layout），是指为不同类型的屏幕创建多种布局，并根据屏幕尺寸类型选择对应的布局。当屏幕尺寸跨越某个边界值时，自动改变页面的布局。
    </p>
    <p class="p margin-b10">
      构建断点自适应布局，可以提升大屏幕上的信息密度，或进一步改善应用的显示效果与使用体验。
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-3-1">
      3.1.3.1 断点与栅格
    </h5>
    <h5 class="h5  margin-b10 padding-l30">
      断点
    </h5>
    <p class="p margin-b10 padding-l30">
      断点是人为设定的宽度或高度的边界值。
    </p>
    <p class="p margin-b10 padding-l30">
      通过断点，设计师与开发者可以将尺寸、比例各不相同的屏幕划分为有限的几个类型，仅针对每个类型设计布局。配合响应式约束，应用可以以很低的成本在所有的屏幕上获得更佳的使用体验。
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      断点值
    </h5>
    <p class="p margin-b10 padding-l30">
      应用页面多为纵向布局，即纵向内容远多于横向内容，且主要通过上下滚动查看。因此，屏幕或窗口尺寸变化时，通常更需要关注宽度对布局的影响，下文所述的断点也主要指宽度断点。
    </p>
    <p class="p margin-b10 padding-l30">
      为了保证应用在海外发行时无需再次适配，我们推荐开发者采用与 Material Design
      相同的断点值，并同样将屏幕分为小、中、大三种类型进行布局设计。
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image15.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image15.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p  padding-l30">
      您可以在这里找到关于 Material Design
      的断点值与定义：
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://m3.material.io/foundations/adaptive-design/large-screens/overview">
        https://m3.material.io/foundations/adaptive-design/large-screens/overview
      </a>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      栅格
    </h5>
    <p class="p margin-b10 padding-l30">
      栅格是对元素横向排布的一种辅助，以规则的列数、边距和间距，来指导元素的分布。
    </p>
    <p class="p margin-b20 padding-l30">
      使用栅格进行布局，可以使元素排列更有秩序感，有助于让应用在不同的设备上呈现相似的布局，或呈现更明确的开发规则。
    </p>
    <h5 class="h5  margin-b20 padding-l30">
      栅格的构成
    </h5>
    <p class="p margin-b10 padding-l30">
      栅格由边距 (Margin)、栏 (Column) 和间距 (Gutter) 组成。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>边距：</b
      >是用来控制元素距离屏幕最边缘的距离关系，可以根据设备的不同尺寸，定义不同的值作为断点系统中的统一规范。边距的大小根据窗口大小和硬件限制变化。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>栏：</b
      >是用来辅助布局的主要定位工具，不同的屏幕尺寸匹配不同的栏数来辅助布局定位。栏的宽度在保证边距和间距符合规范的情况下，根据实际窗口的宽度和栏数自动计算每一栏的宽度。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>间距：</b
      >是用来控制元素和元素之间的距离关系，可以根据设备的不同尺寸，定义不同的间距值作为断点系统中的统一规范。为了保证较好的视觉效果，间距通常的取值不会大于边距的取值。间距的大小一般是固定的。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image16.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image16.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      栅格系统的应用
    </h5>
    <p class="p margin-b10 padding-l30">
      栅格的栏数，取决于所属宽度断点，屏幕越大，则需要更多栏数以细分布局。
    </p>
    <p class="p margin-b10 padding-l30">
      栅格的栏数与屏幕/窗口宽度所属断点范围有关。小屏设备上建议使用 4
      栏，中屏使用 8 栏，大屏使用 12 栏。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image17.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image17.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      分屏、面板、扩充层级等独立窗口或页面，应根据每个页面的宽度判定所属断点与栅格的栏数。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image18.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image18.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      大屏展开侧边导航时，右侧页面暂时被挤压，应保持原有栏数不变。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image19.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image19.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-3-2">
      3.1.3.2 断点自适应布局的基本类型
    </h5>
    <p class="p margin-b10">
      常见的断点自适应布局包括缩进布局，增列布局，挪移布局等。
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      缩进布局
    </h5>
    <p class="p margin-b20 padding-l30">
      显示单列内容时，较大的屏幕会使列表与赋值内容亲密性不明确，用户难以将两侧的内容准确对应。通常情况下，需要对内容进行缩进，使用更少的栏。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image20.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image20.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      4 栏栅格的小屏上不需进行缩进。8 栏的中屏上建议缩进 2 栏，仅使用中间 6
      栏。12 栏的大屏上建议缩进 4 栏，仅使用中间 8 栏。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image21.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image21.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      同样，阅读场景下，文字横向跨度过大会导致阅读困难，容易产生疲劳感，降低信息的获取效果。在大屏幕上，建议也对文本内容进行缩进。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image22.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image22.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      增列布局
    </h5>
    <p class="p margin-b20 padding-l30">
      纵向排列的卡片、宫格或瀑布流内容，在更大的屏幕上需要展示更多的列数，以控制容器和内容的尺寸，或利用增加的宽度显示更多信息。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image23.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image23.png',
        ]"
      >
      </el-image>
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image24.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image24.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      挪移布局
    </h5>
    <p class="p margin-b20 padding-l30">
      当屏幕尺寸跨越断点或比例发生变化时，部分模块的布局可以挪动位置，以提高屏幕的利用效率。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image25.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image25.png',
        ]"
      >
      </el-image>
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image26.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image26.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-3-3">
      3.1.3.3 深度定制布局
    </h5>
    <p class="p margin-b10">
      跨越断点后，应用的布局完全改变。开发者可以自由发挥，进行更深度的设计与定制，展示更丰富、更多层级的信息，或者显露出更多的菜单或内容，提升用户的使用效率。
    </p>
    <p class="p margin-b20">
      同样，还可以针对部分设备的特殊使用姿态定制独特的界面，进一步扩展用户的使用方式。以下为部分定制布局的方法，我们鼓励开发者进行更多尝试，以带给用户更好的体验。
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      扩充层级
    </h5>
    <p class="p margin-b20 padding-l30">
      页面的跳转会导致整页内容变化，操作效率较低，用户也容易忘记自己的位置与所处流程进度。在更大的屏幕上，跳转的过程中用户更容易丧失视觉焦点，这个问题会更加明显。利用大屏的空间，应用可以在上一层的界面内扩充显示下一层级的内容，减少整页刷新的频率，并提升浏览效率。
    </p>
    <p class="p margin-b20 padding-l30">
      常见扩充层级的方式为构建父子级与分栏布局。父子级界面适用于
      IM、笔记、邮箱等列表类应用的主界面。分栏布局适用于社交内容页、音乐播放页等具有相对固定下一层级内容的详情页。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image27.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image27.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      展开功能
    </h5>
    <p class="p margin-b20 padding-l30">
      大屏幕上具有充裕的空间，应用可以减少菜单层级，在界面内直接展开更多的功能，实现类似桌面端的功能布局，提高用户操作效率。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image28.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image28.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      悬停适配
    </h5>
    <p class="p margin-b20 padding-l30">
      折叠屏产品具有独特的悬停支架状态，用户可以将产品半折后立在桌面上，实现离手后的稳定使用。应用可以利用这种状态，提供视频播放、稳定拍摄等功能。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image29.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image29.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p  padding-l30">
      悬停状态下，屏幕一般存在三个区域：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image30.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image30.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p-small margin-b10 padding-l30">
      <b class="padding-l30">①：手指可以舒适地在这个区域进行操作；</b>
    </p>
    <p class="p-small margin-b10 padding-l30">
      <b class="padding-l30"
        >②：手指可以较为舒适地触达这个区域，但操作时容易导致设备丧失平衡；</b
      >
    </p>
    <p class="p-small margin-b20 padding-l30">
      <b class="padding-l30"
        >③：大约为转轴上下 30dp
        之间。手指较容易触达这个区域，但受到折痕与悬停夹角的影响，难以准确在这个区域进行操作。</b
      >
    </p>
    <p class="p margin-b20 padding-l30">
      握持和放置状态下，手指最舒适的操作热区均集中在下半屏，而上半屏拥有更加友好的可视角度。应用在设计悬停状态布局时，应当将显示内容上移，操作内容下沉，尽量将按钮全部设置在区域
      ① 内，避免在区域 ③ 放置任何交互元素。
    </p>
    <p class="p margin-b20">
      相关开发文档参阅
      <a class="a"  href="#4-4-4">
        《4.4.4 折叠屏状态监听和获取》
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b10 nav-title" id="3-1-4">
      3.1.4 优化交互体验
    </h4>
    <p class="p margin-b20">
      使用折叠屏或平板设备时，用户的握持姿态、输入方式与使用场景通常与使用直屏手机有所不同。设计大屏布局时，可能需要考虑这些因素，进一步优化应用的交互体验与可用性。
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-4-1">
      3.1.4.1 为折叠屏优化
    </h5>
    <h5 class="h5  margin-b10 padding-l30">
      设备使用姿态
    </h5>
    <p class="p margin-b10 padding-l30">
      折起时，折叠屏手机通常与普通手机的尺寸与使用姿态较为相似。一般仅需考虑增加的厚度对握持与操作的细微影响，无需针对此状态调整界面布局；
    </p>
    <p class="p margin-b10 padding-l30">
      展开后，设备的尺寸变大，使用体验更接近平板。用户难以单手完成所有操作，常见使用姿态为以下几种：
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 双手握持设备进行使用；
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 一手握持设备，一手操作；
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 单手握持设备观看媒体内容；
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 将设备放置在桌面或稳定表面上进行操作与使用；
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b>
      用悬停姿态或利用支架辅助放置，长时间观看媒体内容。
    </p>
    <p class="p margin-b20 padding-l30">
      其中，双手握持为折叠屏展开后的主要操作姿态之一。该姿态下，屏幕上存在操作体验不佳的区域，建议参考握持操作热区，对应用界面进行调整。
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      握持操作热区
    </h5>
    <p class="p padding-l30">
      双手握持使用折叠屏时，根据手指的触达范围，屏幕通常分为以下四个区域：
    </p>
    <p class="">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image31.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image31.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">①：手指可以舒适地触达该区域； </b>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">②：可以通过伸展手指触达这个区域，但稍微费力； </b>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30"
        >③：可以通过弯曲手指触达这个区域，但较为费力，单手握持姿态下基本难以对这个区域进行操作；
      </b>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">④：如果不改变握持方式，很难触达这个区域。 </b>
    </p>
    <p class="p margin-b20 padding-l30">
      设计折叠屏的展开态布局时，需要充分考虑各区域的覆盖范围，避免把需要频繁操作的按钮放置在手指难以触达的区域。
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-4-2">
      3.1.4.2 为平板优化
    </h5>
    <h5 class="h5 margin-b10 padding-l30">
      设备使用姿态
    </h5>
    <p class="p margin-b10 padding-l30">
      平板设备常见使用姿态为以下几种：
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 一手握持设备，一手操作；
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 将设备放置在膝上或稳定表面上操作与使用；
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 使用支架进行放置，长时间观看媒体内容；
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> 双手握持设备进行拍摄。
    </p>
    <p class="p margin-b10 padding-l30">
      在以上使用姿态下，除拍摄等特殊场景外，屏幕均不存在明显操作困难的区域。但由于屏幕尺寸较大，屏幕边缘的元素相对更不显著，难以被用户关注。建议在设计时考虑将内容向屏幕中央适当集中。
    </p>
    <p class="p margin-b20 padding-l30">
      拍摄场景下，考虑到用户会双手握持设备，建议将功能按钮布置在屏幕中下部的左右两侧边缘，避免在屏幕顶部、底部中央区域放置关键操作。
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      针对输入设备进行适配
    </h5>
    <p class="p margin-b20 padding-l30">
      平板设备具有更多样的输入方式，包括但不限于键盘、鼠标、触控板、手写笔与语音等。在进行适配时，可能需要针对这些输入设备调整应用的界面，或添加对应功能与交互样式。比如为鼠标操作添加悬停与右键点击状态，以及为手写笔添加调节面板等。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image32.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image32.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-4-3">
      3.1.4.3 为大屏幕优化界面与组件
    </h5>
    <h5 class="h5 margin-b10 padding-l30">
      使用模态组件承载临时任务
    </h5>
    <p class="p margin-b10 padding-l30">
      拥有充足的屏幕空间时，应用可以使用模态组件承载一些简单的临时任务，如新建、选取、设置与内容输入等，让用户在感知上不离开核心页面即可完成部分流程；
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image33.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image33.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      优化弹出层
    </h5>
    <p class="p margin-b10 padding-l30">
      大屏上，填满屏幕空间的弹出层会产生更强的遮挡感，并可能造成文字不易读、选项难以对应、按钮可用性下降等问题。弹窗、赋值面板等组件在大屏上显示时，建议呈现为悬浮样式，并对最大尺寸作出约束。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image34.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image34.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      屏幕很大时，请尽量将弹出层显示在用户操作位置的附近。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image35.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image35.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      使用侧边导航
    </h5>
    <p class="p margin-b10 padding-l30">
      随着用户使用姿态的变化，大屏设备上底部导航栏的可用性会下降，按钮也会呈现得更为分散，可能对辨识与使用产生影响。
    </p>
    <p class="p margin-b10 padding-l30">
      我们鼓励应用在较大的屏幕上尝试使用侧边导航，充分利用屏幕空间，提升使用便捷性。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image36.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image36.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b10 nav-title" id="3-1-5">
      3.1.5 多任务与多窗口
    </h4>
    <p class="p margin-b10">
      在折叠屏与平板设备上，用户更愿意利用更大的屏幕空间并行运行多个任务，以提升使用效率与创造生产力。系统提供了以下三种方法，让应用可以以多窗口的形式并行运行。应用可以选择并接入，以满足用户在各种场景下的多任务需求。
    </p>
    <p class="p margin-b10">
      相关开发指南参阅
      <a class="a"  href="#4-3">
        《4.3 多屏多窗口》
      </a>
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image37.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image37.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-1">
      3.1.5.1 分屏
    </h5>
    <h5 class="h5 margin-b10 padding-l30">
      分屏
    </h5>
    <p class="p margin-b10 padding-l30">
      分屏是移动设备上常见的多任务方式，将屏幕划分为两个或多个区域，分配给不同的应用或页面。分屏状态下，窗口的尺寸与位置较为稳定，适用于长时间并行处理两个任务的场景。
    </p>
    <p class="p margin-b10 padding-l30">
      由于不同设备的分屏窗口形式、比例、尺寸存在不同，应用进入分屏后，建议忽略分屏的形式和位置，直接按照原有的断点值，根据窗口的实际宽度来展示对应的布局。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image38.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image38.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-2">
      3.1.5.2 悬浮窗
    </h5>
    <p class="p margin-b10 padding-l30">
      悬浮窗是一种相对临时的多任务处理方式。用户可以对悬浮窗的尺寸、位置进行调整，也可以使悬浮窗吸附并隐藏在屏幕边缘。由于尺寸较小，在多任务场景下，悬浮窗主要用于呈现内容或临时处理简单的工作。
    </p>
    <p class="p margin-b10 padding-l30">
      同样，应用也可以利用悬浮窗开启新的页面，实现同一应用内的并行工作。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image41.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image41.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-3">
      3.1.5.3 平行视窗/应用多窗/平行窗口
    </h5>
    <p class="p margin-b10 padding-l30">
      平行视窗（vivo 称应用多窗，小米称平行窗口，OPPO称平行视窗）是一种由系统提供的能力。应用可以通过接入框架，在同屏内开启多个实例页面，以达到显示更多内容的目的。
    </p>
    <p class="p margin-b10 padding-l30">
      平行视窗主要用于外卖、电商、生活服务等类型应用。此类应用存在大量页面，使用平行视窗，可以在未做界面适配时提供基础的体验优化。平行视窗一般由应用自动拉起，或按照系统设置针对整个应用开启，不需用户手动介入。
    </p>
    <p class="p margin-b10 padding-l30">
      相关开发指导请参阅
      <a class="a"  href="#4-5">
        《4.5 Activity Embedding》
      </a>
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image42.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image42.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      平行视窗下，用户的操作依然会引发两侧页面同时变化，操作区域不稳定，使用体验不佳。如果应用有条件针对大屏设计布局，我们不推荐使用这种方法进行适配。
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-4">
      3.1.5.4 跨应用操作
    </h5>
    <p class="p margin-b10 padding-l30">
      在多窗口场景下，可以允许用户进行一些符合直觉的跨应用操作，如内容拖放等，进一步减少跨应用分享操作步骤，发挥大屏幕在操作效率上的优势。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image43.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image43.png',
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="3-2">
      3.2 场景适配案例
    </h3>
    <p class="p margin-b20">
      我们从一些常见的典型场景入手，整理出了相对通用化的折叠屏设计案例。开发者可以参考这些案例，结合自己应用的业务类型及体验场景，进行折叠屏上的布局适配。
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-1">
      3.2.1 首页类场景
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      综合型首页
    </h5>
    <p class="p margin-b10 padding-l30">
      综合型首页通常具有标签栏、按钮组、可滑动元素与轮播图等内容。
    </p>
    <p class="p margin-b10 padding-l30">
      通常情况下，如果应用已经设置了放大倍率，并使用了响应式布局，此类页面可以自然适配到中屏与大屏上。部分原本需要滚动浏览的内容会直接展现出来。
    </p>
    <p class="p margin-b10 padding-l30">
      对于可以滚动的轮播图，直接等比放大会占用大量页面空间，影响底部内容的查看。建议保持显示高度不变，在两侧扩展显示前后几张。
    </p>
    <p class="p margin-b10 padding-l30">
      如果存在固定的 Banner，建议分离背景与内容，仅对背景元素进行放大。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image44.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image44.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      卡片流首页
    </h5>
    <p class="p margin-b10 padding-l30">
      卡片流首页一般常见于视频/图片社区与生活服务类应用中。在适配大屏设备时，应当增加卡片的列数，防止媒体内容过大，影响美观度与信息浏览效率。
    </p>
    <p class="p margin-b10 padding-l30">
      纯图片/视频卡片可以根据屏幕栅格增列，通常在中屏上显示小屏 2 倍的列数，大屏上显示 3 倍的列数。但对于存在文字内容的混合卡片，由于卡片高度相互不对齐，建议在增列后仍然显示较少的列数，以免让用户眼花缭乱，丢失阅读的位置与进度。
    </p>
    <p class="p margin-b10 padding-l30">
      如果不便按照栅格进行适配，可以在中、大屏上控制单一媒体内容的宽度不超过屏幕的 30%，高度不超过屏幕的 50%，保证内容的基本可读性。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image45.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image45.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      信息流首页
    </h5>
    <p class="p margin-b10 padding-l30">
      信息流首页常见于社交、资讯类应用。用户通常需要高效进行流式浏览，建议对内容两侧进行缩进，控制单行的文字数量，提升阅读效率。
    </p>
    <p class="p margin-b10 padding-l30">
      放大信息流中的媒体内容会严重影响单页中的信息量，因此建议使用屏幕的放大倍率约束图片、视频的显示尺寸，或始终令其保持与直屏手机相近的显示大小，保证一屏内的信息总量不降低。
    </p>
    <p class="p margin-b10 padding-l30">
      通常情况下，不建议对信息流页面进行增列，用户阅读的视线会被打乱，获取信息的效率也会降低。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image46.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image46.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-2">
      3.2.2 影音娱乐类场景
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      视频详情
    </h5>
    <p class="p margin-b10 padding-l30">
      在更大的屏幕上，视频详情页可以调整框架结构，利用屏幕的宽度高效展示信息。
    </p>
    <p class="p margin-b10 padding-l30">
      如将推荐/评论标签页拆解展开在页面中，会减少内容层级，增强内容曝光效果。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image50.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image50.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      沉浸视频
    </h5>
    <p class="p margin-b10 padding-l30">
      沉浸视频页是一种充分利用屏幕宽度，让用户在不进入全屏的情况下即可观看全屏尺寸视频的界面结构。
    </p>
    <p class="p margin-b10 padding-l30">
      使用沉浸视频页进行设计时，请注意设备的屏幕比例。建议在视频区域外留出屏幕高度
      30% 以上的空间放置内容，避免用户在操作与浏览的过程中产生困难。
    </p>
    <p class="p margin-b10 padding-l30">
      *由于平板设备屏幕长宽比例与主流视频相似，沉浸视频设计可能导致页面其他内容无法展示，我们推荐应用在这种情况下仍然使用普通的分栏布局。
    </p>

    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image51.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image51.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      悬停视频
    </h5>
    <p class="p margin-b10 padding-l30">
      半折悬停是折叠屏机型上的一种特殊状态。进入悬停后，用户可以将手机放置在桌面上，实现无支架脱手观看视频。或将手机放置在地面上，跟随视频进行室内健身活动。
    </p>
    <p class="p margin-b10 padding-l30">
      悬停状态下，应用需遵循“上部展示内容，下部扩展操作”的逻辑进行设计。屏幕上部播放视频与显示信息，下部放置操作按键，保证用户操控内容便捷集中，且操作时手机稳定不倾倒。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image52.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image52.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      短视频快速查看
    </h5>
    <p class="p margin-b10 padding-l30">
      短视频通常采用纵向比例，在折叠屏上的屏幕空间利用率较低。
    </p>
    <p class="p margin-b10 padding-l30">
      可以允许用户划动呼出作者主页或播放列表，在播放的同时快捷查看与切换视频。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image53.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image53.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      直播评论分离
    </h5>
    <p class="p margin-b10 padding-l30">
      同样，部分纵向比例的直播页面，在大屏上也可以对视频与评论进行分离，既保证了视频内容的完整展示，也可以显示更多互动内容，或进行运营内容的露出。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image54.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image54.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      音乐播放
    </h5>
    <p class="p margin-b10 padding-l30">
      应用可以将歌词等内容展开到音乐播放页中，充分利用屏幕空间。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image55.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image55.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      播放中查看内容
    </h5>
    <p class="p margin-b10 padding-l30">
      在更大的屏幕上，可以允许用户在音乐播放时临时使用部分屏幕空间查看评论、专辑信息等内容。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image57.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image57.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-3">
      3.2.3 通讯类场景
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      IM 与邮件列表页
    </h5>
    <p class="p margin-b10 padding-l30">
      即时通讯与邮件应用应当使用父子级结构，将下一层级内容提升至子级中，减少应用层级，提升浏览效率。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image58.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image58.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      视频通话
    </h5>
    <p class="p margin-b10 padding-l30">
      视频通话中，应当保持画面显示尽量完整，请勿直接填充屏幕，造成画面截断。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image59.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image59.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      此外，在更大的屏幕上，应用也可以采用均分的方式展示通话双方的视频。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image60.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image60.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      悬停通话
    </h5>
    <p class="p margin-b10 padding-l30">
      视频通话或多人会议场景下，用户也可以将手机放置在桌面上，进行脱手悬停通话。
    </p>
    <p class="p margin-b10 padding-l30">
      悬停状态下，应用需遵循“上部展示内容，下部扩展操作”的逻辑进行设计。屏幕上部播放视频与显示信息，下部放置操作按键，保证用户操控便捷，且操作时手机不易倾倒。
    </p>
    <p class="margin-b30 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image61.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image61.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-4">
      3.2.4 论坛社区类场景
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      图文详情页
    </h5>
    <p class="p margin-b10 padding-l30">
      社区内容的图文详情页，应当避免将图片直接放大，影响美观度与浏览效率。
    </p>
    <p class="p margin-b10 padding-l30">
      建议调整页面框架，使用分栏布局，将评论或推荐内容放置在屏幕右侧。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image65.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image65.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      论坛内容详情页
    </h5>
    <p class="p margin-b10 padding-l30">
      内容详情页中，用户使用方式类似于流式阅读。建议对内容两侧进行缩进，方便用户高效阅读文字内容。在平板上显示时，可以利用缩进后的空间展示相关推荐内容。
    </p>
    <p class="p margin-b10 padding-l30">
      同样，图片与视频元素应当使用放大倍率进行适配，或保持与直屏手机相似的显示大小。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image66.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image66.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4  margin-t20 margin-b20 nav-title" id="3-2-5">
      3.2.5 资讯类场景
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      资讯列表页
    </h5>
    <p class="p margin-b10 padding-l30">
      资讯列表页存在大量流式内容。建议对页面两侧进行缩进，方便用户阅读。
    </p>
    <p class="p margin-b10 padding-l30">
      同样，图片与视频元素应当使用放大倍率进行适配，或保持与直屏手机相似的显示大小。单行多图组合可以保持框架缩放，但单一图片的宽度建议不超过内容区域整体宽度的 30%。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image67.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image67.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      资讯内容页
    </h5>
    <p class="p margin-b10 padding-l30">
      资讯内容页一般为图文混合内容，存在较高阅读强度。通常需要对内容两侧进行缩进，提升用户阅读舒适度。
    </p>
    <p class="p margin-b10 padding-l30">
      平板设备屏幕较大，如果缩进后单行文字依然过多，可以适当提升内容的字号。
    </p>
    <p class="p margin-b10 padding-l30">
      图片与视频元素可以缩放到与文字内容等宽，但由于平板设备屏幕比例较为特殊，建议对媒体内容的最大高度进行限制，以免影响内容的浏览与获取。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image68.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image68.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-6">
      3.2.6 游戏类场景
    </h4>
    <p class="p margin-b10 padding-l30">
      对于游戏类场景，建议开发者根据不同的游戏类型和场景，优化大屏布局，在呈现与交互层面给用户带来愉悦的体验。同时，考虑到用户在玩游戏时长时间握持设备，在设计展开态布局时，应根据不同的交互区做适配优化。
    </p>
    <p class="p margin-b10 padding-l30">
      考虑到游戏类型多种多样，此处仅提供适配时需要规避的不推荐策略。
    </p>
    <p class="p margin-b10 padding-l30">
      不推荐1：在大屏下游戏界面按原比例居中显示，其他部分留白/留黑，没有充分利用大屏优势。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image69.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image69.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      不推荐2：大屏下等比放大操作控件与游戏画面，导致用户视野变小，信息不完整。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image70.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image70.png',
        ]"
      >
      </el-image>
    </p>
    
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="4">
      四、 开发指导
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-1">
      4.1 屏幕兼容性
    </h3>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-1-1">
      4.1.1 应用resizeable能力支持
    </h4>
    <p class="p margin-b10 padding-l30">
      Android 设备的形状和尺⼨多种多样，因此应⽤的布局需要⼗分灵活。也就是说，布局应该从容应对不同的屏幕尺⼨和⽅向，⽽不是为布局定义刚性尺⼨，假定屏幕尺⼨和宽⾼⽐是⼀定的。
    </p>
    <p class="p margin-b10 padding-l30">
      所以多形态屏幕切换适配的本质是：
      <b
        >当应⽤运⾏时，屏幕的尺⼨、密度或⽐例发⽣了变化，应⽤能够继续在变化后的屏幕上正常显示和正常运⾏。</b
      >
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image78.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image78.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      本节概述了这些主题以及 Android 上已有的可⽤功能，以帮助您的应⽤进⾏相应调整,⽀持不同屏幕尺⼨。
    </p>
    <p class="p margin-b10 padding-l30">
      确保您的应⽤界⾯在不同的屏幕尺⼨下可以全屏的显示，
      <b>强烈推荐应用配置resizeableActivity为true以支持全屏显示</b>
    </p>
    <p class="p margin-b10 padding-l30">
      要适配多形态屏幕切换，⾸先是要让应⽤⽀持动态改变尺⼨，我们需要在 manifest
      中的 Application 或对应的 Activity 下声明
    </p>
    <div class="code margin-b10 margin-l30">
      <p class="p ">android:resizeableActivity="true"   </p>  
    </div>

    <p class="p margin-b10 padding-l30">
      您需要根据应⽤⾯向的API Level
      （targetSdkVersion）进⾏⽀持resizeable能⼒的声明。
    </p>
    <p class="p margin-b10 padding-l30">
      ● 如果应⽤程序⾯向API Level
      24以上（targetSdkVersion>=24），系统将默认应⽤⽀持resizeable能⼒。
    </p>
    <p class="p margin-b10 padding-l30">
      ● 如果应⽤程序⾯向API Level 24以下（targetSdkVersion &lt;
      24），需要应⽤在manifest中显式的声明android:resizeableActivity=true，
      才可以⽀持resizeable能⼒ 。
    </p>
    <p class="p margin-b10">
      备注：虽然安卓提供了申请受限屏幕能力，但强烈建议您为应用设计resizeable能力，因为一旦您声明了受限屏幕比例（最大或最小）这意味着，当您的应用程序运行在一个屏幕比例超出了您声明的范围，您的应用程序在屏幕上将出现黑边等现象，严重影响应用程序的用户体验。受限屏幕支持开发者适配指导如下
      <a
        class="a"
        href="https://developer.android.google.cn/guide/practices/screens-distribution.html"
      >
        <i class="el-icon-paperclip"></i>
        关于声明受限屏幕的使用说明
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-1-2">
      4.1.2 应用布局优化
    </h4>
    <p class="p margin-b10 padding-l30">
      在对不同尺⼨屏幕适配过程中，为了确保在多形态屏幕下获取最佳的布局显示效果，例如显示更多更清晰的内容，建议您对布局进⾏优化。
    </p>
    <p class="p margin-b10 padding-l30">
      应⽤界⾯正确、美观的布局和显示，包含如下：
    </p>
    <p class="p margin-b10 padding-l30">
      ● 确保您的布局能够根据屏幕适当地调整⼤⼩；
    </p>
    <p class="p margin-b10 padding-l30">
      ● 根据屏幕配置提供合适的 UI 布局；
    </p>
    <p class="p margin-b10 padding-l30">
      ● 确保对正确的屏幕应⽤正确的布局；
    </p>
    <p class="p margin-b10 padding-l30">
      ● 提供可正常缩放的位图。
    </p>
    <p class="p margin-b10 padding-l30">
      详细信息请参阅第三章<a
        class="a"
        href="#3"
      >
        《设计指南》
      </a>
      ，也可辅助参考Android开发者指南中
      <a
        class="a"
        href="https://developer.android.google.cn/training/multiscreen/screensizes?"
      >
        <i class="el-icon-paperclip"></i>
        支持不同的屏幕尺寸
      </a>
      、
      <a
        class="a"
        href="https://developer.android.com/guide/topics/large-screens/migrate-to-responsive-layouts"
      >
        <i class="el-icon-paperclip"></i>
        将界面迁移到自适应布局
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-2">
      4.2 应用连续性
    </h3>
    <p class="p margin-b10 padding-l30">
      为了保证您的应⽤程序在屏幕切换过程中⽆缝切换，您需要做应⽤连续性的设计，以确保您的应⽤程序任务不中断。最佳的体验为：应⽤在展开切换过程中，不发⽣应⽤的重启，且切换之前的任务和应⽤相关状态得以保存和延续。
    </p>
    <p class="p margin-b10 padding-l30">
      三方应用支持连续性，需要在 AndroidManifest.xml 文件的 application 或者 actvivity 标签中添加 resizeableActivity=true 的属性：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">&lt;application android:resizeableActivity="true">    </p>  
      <p class="p padding-l30">&lt;activity android:resizeableActivity="true" />  </p>
      <p class="p ">&lt;/application> </p>
    </div>
    <p class="p margin-b10 padding-l30">
      若应用的 targetSDK 为 24 或以上，即便不设置 resizeableActivity 属性，其默认也为 true。在设备发生屏幕切换后，应用应能妥善地保存界面状态或者支持配置变更。
    </p>
    <p class="p margin-b10 padding-l30">
      多形态屏幕切换的动作<b>（折叠屏或者投屏）</b>，会触发对smallestscreensize、screensize和screenlayout以及方向和密度的配置更改。每当发⽣配置更改时，默认情况下会销毁并重新创建整个activity。推荐您通过注册监听系统configchanges消息，不重启应⽤的情况下处理配置更改，您需要向manifest中添加android:configchanges属性，其中⾄少包含以下值：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">android:configChanges="screenSize|smallestScreenSize|screenLayout|orientation|density"</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      其中，orientation在一些折叠屏展开默认横屏握持的设备上尤其重要，因为厂商会强制部分应用横屏显示；density在投屏场景应用较多，投屏场景无法保证不同屏幕的density是一致的。您需要复写 onConfigurationChanged() ⽅法，通过该⽅法的Configuration参数获得屏幕的分辨率、密度等信息，就可以针对不同⽐例屏幕下的应⽤界⾯布局做相应调整，如切换布局、调整控件位置和间距等。
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">@Override</p>
      <p class="p ">public void onConfigurationChanged(Configuration newConfig) {</p>  
      <p class="p padding-l30">super.onConfigurationChanged(newConfig);</p> 
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      如果您的应⽤必须进⾏重新⾛⽣命周期来响应屏幕切换，需要进⾏状态的保存和恢复。
    </p>
    <p class="p margin-b10 padding-l30">
      您可以通过onSaveInstanceState()和ViewModel对象来进⾏之前状态保存和后续的恢复。即，在销毁activity之前，通过onSaveInstancesState()存储状态， 在onCreate() or onRestoreInstanceState()进⾏状态的恢复。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Note：</b>应用不要在OnDestroy()中调⽤finish()或其他方式⾃⾏终⽌进程。这将导致应⽤程序在设备多形态屏幕切换时关闭、闪退等问题。
    </p>
    <p class="p margin-b10 padding-l30">
      应用不要自行hook资源上下文的config，而应由系统统一创建config和Resources对象。
    </p>
    <p class="p margin-b10 padding-l30">
      详细信息请参阅Android开发者指南中
      <a
        class="a"
        href="https://developer.android.com/guide/topics/resources/runtime-changes"
      >
        <i class="el-icon-paperclip"></i>
        处理配置变更
      </a>
      、
      <a
        class="a"
        href="https://developer.android.com/topic/libraries/architecture/saving-states?hl=zh-cn"
      >
        <i class="el-icon-paperclip"></i>
        保存界面状态
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-2-1">
      4.2.1 正确使用应用资源
    </h4>
    <p class="p margin-b10 padding-l30">
      1、无论是多窗口场景还是全屏场景，都强烈建议应⽤布局View要以窗⼝⼤⼩进⾏（因为多窗口场景窗口大小和屏幕大小并不等价），不可以按照屏幕⼤⼩布局，比如在窗⼝模式下还是以屏幕的宽⾼进⾏布局，则会导致应⽤的图标截断，布局错乱等布局问题。
    </p>
    <p class="p margin-b10 padding-l30">
      2、推荐使用Activity上下文去获取窗口大小而不是获取屏幕大小，因为Activity Embedding、分屏、悬浮窗等多窗口场景屏幕大小与窗口大小不相等。
    </p>
    <p class="p margin-b10 padding-l30">
      Android R开始：
    </p>
    <p class="p margin-b10 padding-l30">
      获取当前 activity 窗口大小：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">android.view.WindowMetrics windowMetrics = activity.getWindowManager().getCurrentWindowMetrics();</p>
      <p class="p ">Rect windowRect = windowMetrics.getBounds();</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      获取当前activity所在的屏幕大小：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">android.view.WindowMetrics maxWindowMetrics = this.getWindowManager().getMaximumWindowMetrics();</p>
      <p class="p ">Rect maxWindowRect = maxWindowMetrics.getBounds();</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      Android R之前版本：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">androidx.window.layout.WindowMetricsCalculator wtc = androidx.window.layout.WindowMetricsCalculator.getOrCreate();</p>
      <p class="p ">androidx.window.layout.WindowMetrics windowMetrics = wtc.computeCurrentWindowMetrics(activity);</p>  
      <p class="p ">Rect windowRect = windowMetrics.getBounds();</p>
      <p class="p ">androidx.window.layout.WindowMetrics maxWindowMetrics = wtc.computeMaximumWindowMetrics(activity);</p>  
      <p class="p ">Rect maxWindowRect = maxWindowMetrics.getBounds();</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      Android R之前版本需要在应用或模块的 build.gradle 文件中添加所需工件的依赖项：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">dependencies {</p>
      <p class="p padding-l30">implementation "androidx.window:window:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For Java-friendly APIs to register and unregister callbacks</p>  
      <p class="p padding-l30">implementation "androidx.window:window-java:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For RxJava2 integration</p>  
      <p class="p padding-l30">implementation "androidx.window:window-rxjava2:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For RxJava3 integration</p>  
      <p class="p padding-l30">implementation "androidx.window:window-rxjava3:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For testing</p>
      <p class="p padding-l30">implementation "androidx.window:window-testing:1.1.0-alpha02"</p>
      <p class="p ">}</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      详细信息请参阅Android开发者指南中
      <a
        class="a"
        href="https://developer.android.com/jetpack/androidx/releases/window"
      >
        <i class="el-icon-paperclip"></i>
        WindowManager JetPack
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      3、推荐使用Activity上下文去获取资源Configuration。
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">Configuration config = activity.getResources().getConfiguration();</p>
    </div>
    <p class="p margin-b10 padding-l30">
      应用不要hook获取资源activity.getResources()的接口，即应用不要自己构造Configuration和Resources去主动控制Configuration的分发和刷新。
    </p>
    <p class="p margin-b10 padding-l30">
      4、正确获取控件View的位置
    </p>
    <p class="p margin-b10 padding-l30">
      在多窗口情况下调用View的getLocationOnScreen和getLocationInWindow接口含义不同，前者拿到的控件在屏幕上的绝对位置（相对屏幕左上角起点），后者是控件在窗口内部的相对位置（相对于窗口左上角起点）。
    </p>
    <p class="p margin-b10 padding-l30">
      5、使用合适的位图
    </p>
    <p class="p margin-b20 padding-l30">
      在大屏设备上，图片如果被放大可能会显示不清晰，为此在需要放大的场景下，建议提供更高密度级别的图片并放到mipmap目录下，或者改矢量图形。在部分显示区域不能放大的场景使用.9图片和矢量图。
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-2-2">
      4.2.2 动态支持横竖屏切换
    </h4>
    <p class="p margin-b10 padding-l30">
      应用根据设备分辨率高宽比动态设置仅支持竖屏（或者横屏）和支持横竖屏切换，应用最佳体验是在小屏手机形态支持单一方向，比如竖屏或者横屏，大屏高宽接近的设备支持旋转屏（游戏、相机等特殊类型应用例外），如果应用设计与此目标相符，可以使用以下样例代码动态变更方向。
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public class MainActivity extends AppCompatActivity {</p>
        <p class="p padding-l30">@Override</p>  
        <p class="p padding-l30">protected void onCreate(Bundle savedInstanceState) {</p>  
          <p class="p padding-l60">super.onCreate(savedInstanceState);</p>  
          <p class="p padding-l60">setRequestedOrientation(this, this.getResources().getConfiguration());</p>  
          <p class="p padding-l60">setContentView(R.layout.activity_main);</p>  
        <p class="p padding-l30">}</p> 

        <p class="p padding-l30">@Override</p>  
        <p class="p padding-l30">public void onConfigurationChanged(@NonNull Configuration newConfig) {</p>    
          <p class="p padding-l60">super.onConfigurationChanged(newConfig);</p>  
          <p class="p padding-l60">setRequestedOrientation(this, newConfig);</p>  
          <p class="p padding-l60">// update layout and redraw</p>  
        <p class="p padding-l30">}</p> 

        <p class="p padding-l30">public static void setRequestedOrientation(Activity activity, Configuration config) {</p>  
          <p class="p padding-l60">float longSide = Math.max(config.screenWidthDp, config.screenHeightDp);</p>  
          <p class="p padding-l60">float shortSide = Math.min(config.screenWidthDp, config.screenHeightDp);</p>  
          <p class="p padding-l60">boolean isHeighRatio = longSide / shortSide > 16f / 9;</p>  
          <p class="p padding-l60"> if (isHeighRatio) {</p>  
            <p class="p padding-l90">// phone, foldable device in folded mode, pocket phone in expanded mode</p>  
            <p class="p padding-l90">activity.setRequestedOrientation(ActivityInfo.SCREEN_ORIENTATION_PORTRAIT);</p>  
          <p class="p padding-l60">} else {</p>  
            <p class="p padding-l90">// tablet, foldable device in expanded mode, pocket phone in folded mode, tv</p>  
            <p class="p padding-l90">activity.setRequestedOrientation(ActivityInfo.SCREEN_ORIENTATION_UNSPECIFIED);</p>  
          <p class="p padding-l60">}</p>  

        <p class="p padding-l30">}</p> 
      <p class="p ">}</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      详细请参考Google官方文档：
      <a
        class="a"
        href="https://developer.android.com/guide/topics/ui/foldables?hl=zh-cn#应用连续性"
      >
        <i class="el-icon-paperclip"></i>
        应用连续性
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-3">
      4.3 多屏多窗口
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-1">
      4.3.1 支持分屏和悬浮窗模式
    </h4>
    <p class="p margin-b10 padding-l30">
      应⽤需要⽀持resizeable，以允许系统根据⽤户场景需要触发按分屏或悬浮窗模式启动应⽤。
    </p>
    <p class="p margin-b10 padding-l30">
      详细信息请参阅Android开发者指南中
      <a
        class="a"
        href="https://developer.android.com/guide/topics/ui/multi-window"
      >
        <i class="el-icon-paperclip"></i>
        多窗口支持
      </a>
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-2">
      4.3.2 多项恢复
    </h4>
    <p class="p margin-b10 padding-l30">
      在搭载 Android 9.0 及更低版本的设备上运⾏时，只有获得焦点的应⽤处于已恢复状态(Resumed)。任何其他可⻅ Activity 都处于已暂停状态(Paused)。如果应⽤在处于暂停状态时关闭资源或停⽌播放内容，则可能会产⽣问题。
    </p>
    <p class="p margin-b10 padding-l30">
      从 Android 10 开始，此⾏为发⽣了变化，即当设备处于多窗⼝模式时，所有 Activity 都会保持已恢复状态。这称为多项恢复(Multi-resume)。请注意，如果顶部有透明 Activity，或者Activity 不可成为焦点（例如，处于画中画模式），则相应 Activity 可能会处于已暂停状态。还有⼀种可能是，在特定时间内（例如，当打开抽屉式通知栏时）所有 Activity都不具有焦点。onStop会继续照常⼯作。每当 Activity 从屏幕上移除时，系统都会调⽤它。
    </p>
    <p class="p margin-b10 padding-l30">
      部分搭载 Android 9.0 的设备也提供多项恢复功能。如要在这些设备上选择启⽤多项恢复功能，您可以添加以下清单元数据：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">
        &lt;meta-data android:name="android.allow_multiple_resumed_activities" android:value="true" />
      </p>
    </div>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-3">
      4.3.3 专属资源访问
    </h4>
    <p class="p margin-b10 padding-l30">
      为了帮助⽀持多项恢复功能，Android提供了⼀个新的⽣命周期回调Activity#onTopResumedActivityChanged()。
    </p>
    <p class="p margin-b10 padding-l30">
      当 Activity 获得或失去顶部恢复 Activity 位置时(即处于Multi-resume的多个Resumed状态的activity在获取焦点或者丢失焦点时)，系统会调⽤此⽅法。当 Activity 使⽤共享的单⽤户资源（例如⻨克⻛或摄像头）时，了解这⼀点⾄关重要。
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">
        protected void onTopResumedActivityChanged(boolean topResumed) {
      </p>
        <p class="p padding-l30">
          if (topResumed) {
        </p>
        <p class="p padding-l30">
          } else {
        </p>
        <p class="p padding-l30">
          }
        </p>
      <p class="p ">
        }
      </p>
    </div>
    <p class="p margin-b10 padding-l30">
      请注意，应⽤可能会因其他多种原因丢失资源，例如移除共享硬件。
    </p>
    <p class="p margin-b10 padding-l30">
      在任何情况下，应⽤都应妥善处理会影响可⽤资源的资源丢失事件和状态更改。
    </p>
    <p class="p margin-b10 padding-l30">
      对于使⽤摄像头的应⽤，建议使⽤ 
    </p>
    <p class="p margin-b10 padding-l30">
      CameraManager.AvailabilityCallback#onCameraAccessPrioritiesChanged() ⽅法作为提示，提醒这可能是尝试访问摄像头的好时机。此⽅法在Android 10 (API 级别 29) 或更⾼版本 4.3.3 中可⽤。
    </p>
    <p class="p margin-b10 padding-l30">
      当收到Activity#onTopResumedActivityChanged(topResumed)回调时，
    </p>
    <p class="p margin-b10 padding-l30">
      ● topResumed = false时，需要在此时判断是否释放独占资源，而不必在一失去焦点时就释放资源；
    </p>
    <p class="p margin-b10 padding-l30">
      ● topResumed = true时 ，可以申请独占的摄像头资源，原持有摄像头资源的应用将收到 CameraDevice.StateCallback#onDisconnected() 回调后，对摄像头设备进行的后续调用将抛出 CameraAccessException
    </p>
    <p class="p margin-b20 padding-l30">
      请记住，resizeableActivity=false 并不保证可以获取对摄像头的专属访问权限，因为其他使⽤摄像头的应⽤可能会在其他显示屏上打开。
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image79.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image79.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p w750 margin-b20  padding-l60 text-center">
      多窗⼝模式下的摄像头
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-4">
      4.3.4 多窗口下请求方向
    </h4>
    <p class="p margin-b10 padding-l30">
      在多窗⼝模式下，运⾏时锁定朝向的⽅法都是⽆效的，也就是说多窗口场景下，仅支持竖屏的应用也有可能存在窗口宽比高大这种横屏的场景，所以界面布局一定要同时考虑宽比高小和宽比高大两类场景。
    </p>
    <p class="p margin-b10 padding-l30">
      1）setRequestedOrientation()
    </p>
    <p class="p margin-b10 padding-l30">
      2）android:screenOrientation
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-5">
      4.3.5 多显示屏
    </h4>
    <p class="p margin-b10 padding-l30">
      Android 10 (API 级别 29) 或更⾼版本⽀持辅助显示屏上的 Activity。如果 Activity 在具有多个显示屏的设备上运⾏，则⽤户可以将 Activity 从⼀个显示屏移到另⼀个显示屏。多项恢复功能也适⽤于多屏场景。多个 Activity 可以同时接收⽤户输⼊。
    </p>
    <p class="p margin-b10 padding-l30">
      应⽤可以指定在启动或创建其他 Activity 时它应该在哪个显示屏上运⾏。该⾏为取决于清单⽂件以及 Intent 标记和选项（由启动 Activity 的实体设置）中定义的 Activity 启动模式。
    </p>
    <p class="p margin-b10 padding-l30">
      与多形态屏幕切换⼀样，当 Activity 移⾄辅助显示屏时，系统会更新上下⽂、调整窗⼝⼤⼩，并更改配置和资源。如果由该 Activity 来处理配置变更，则其会在 onConfigurationChanged() 中收到通知。如果不是，则其会重新启动。
    </p>
    <p class="p margin-b10 padding-l30">
      如果配置变更已处理，则 Activity 应该在 onCreate 和 onConfigurationChanged 中检查当前显示屏。确保在显示屏变更时更新资源和布局。
    </p>
    <p class="p margin-b10 padding-l30">
      如果为 Activity 选择的启动模式⽀持多个实例，则请记住，在辅助屏幕上启动时会新建⼀个 Activity 实例。这两个 Activity 会同时恢复。
    </p>
    <p class="margin-b10">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image80.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image80.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      ⼀个 Activity 在多个显示屏中有多个实例。
    </p>
    <p class="p margin-b10 padding-l30">
      更多多显示屏相关内容请参阅Android开发者指南中
      <a
        class="a"
        href="https://developer.android.com/about/versions/oreo/android-8.0#mds"
      >
        <i class="el-icon-paperclip"></i>
        多显示屏
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-4">
      4.4 折叠屏适配
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-4-1">
      4.4.1 折叠屏分类
    </h4>
    <p class="p margin-b10 padding-l30">
      目前纵向折叠屏，与普通直屏手机，仅有屏幕可折叠的差异，此处不做讨论。
    </p>
    <p class="p margin-b10 padding-l30">
      在横向折叠屏中，有两种形态存在，展开为默认为横屏的折叠屏设备和展开默认为竖屏的折叠屏设备。
    </p>
    <h4 class="h4 margin-t30 nav-title" id="4-4-2">
      4.4.2 两类折叠屏差异
    </h4>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image81.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image81.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      展开默认是竖屏的折叠屏设备，展开大屏竖屏的rotation是0度、屏幕方向是portrait、宽小于高，与普通手机认知一致。
    </p>
    <p class="p margin-b10 padding-l30">
      对于展开默认是横屏的设备，在展开大屏横屏时rotation是0度，屏幕方向是landscape，宽大于高。
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-4-3">
      4.4.3 折叠屏适配建议
    </h4>
    <p class="p margin-b10 padding-l30">
      1、推荐应用根据设备分辨率高宽比动态设置仅支持竖屏和支持横竖屏切换。
    </p>
    <p class="p margin-b10 padding-l30">
      2、响应onConfigurationChanged保证业务连续性。
    </p>
    <p class="p margin-b10 padding-l30">
      3、应用做布局时不要使用rotation，而是根据宽高的大小做布局处理，避免两类折叠屏重复适配布局问题。
    </p>
    <p class="p margin-b10 padding-l30">
      4、应用布局同时考虑横竖屏方向，比如SurfaceView实现的直播和短视频页面，以及Webview加载H5实现的页面比如运营页、小程序/小游戏等尤其需要同时考虑宽高两个方向尺寸进行布局，以应对第二类折叠屏展开显示场景。
    </p>
    <p class="p margin-b10 padding-l30">
      5、对于一些视频最大化横屏播放场景，判断点击最大化的逻辑，建议不要与横竖屏状态关联，而是维护一个是否已经最大化的状态。
    </p>
    <p class="p margin-b10 padding-l30">
      6、使用摄像头时要注意横竖屏场景以及前后置摄像头，及时在生命周期中刷新预览方向和图片大小，确保设备旋转能够正常显示预览和拍照。
    </p>
    <p class="p margin-b10 padding-l30">
      7、对于宽高比小于16:9的窗口，避免根据屏幕宽度来缩放density或者重写measure，H5不设置viewport的scale，改用增加横向列表数量，或增加间距的方式铺满屏幕。
    </p>
    <p class="p margin-b10 padding-l30">
      8、考虑折叠屏大屏宽大于高的情况，游戏的画面铺满整个屏幕，避免出现没有铺满屏幕导致的花屏现象。
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-4-4">
      4.4.4 折叠屏状态监听和获取
    </h4>
    <p class="p margin-b10 padding-l30">
      1、折叠屏折叠和展开仅仅是display的尺寸发生变化，并通过onConfigurationChanged通知应用去更新布局，应用在onConfigurationChanged中获取自身的WindowMetrics去动态自适应布局，应用不应关心折叠和展开的状态。
    </p>
    <p class="p margin-b10 padding-l30">
      2、vivo(从Android 12L开始)、OPPO和小米（从Android T版本开始），均支持通过Google的JetPack去感知展开态和半折态和半折态区域，用于应用监听半折态做悬停布局，目前提供的展开态和半折态分别是FLAT和HALF_OPENED，提供展开态和半折态的区分是因为这两种状态都处于同一display尺寸下，并不会有onConfigurationChanged上报给应用，所以只能新增监听让应用可以辨识出展开态和半折态，在展开和半折的状态切换回调中做布局更新，达到半折态悬停效果。
    </p>
    <p class="p margin-b10 padding-l30">
      折叠屏半折有两种情况：
    </p>
    <p class="p padding-l30">
      1）上下对折，下半屏放置桌面上使用，即TableTop桌面模式
    </p>
    <p class="p padding-l30">
      2）左右对折，像翻书一样使用，即Book书本模式
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w600"
        :src="configBaseIMG+'/whiteBookFile/image82.jpg'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image82.jpg',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      <b>注意：如果应用支持上面两种状态的切换，切换时状态会重新通知，即若应用页面支持旋转屏，则转屏时对折方向会发生变化，系统会重新通知监听者，应用可以在收到监听时重新获取对折方向进行布局更新。</b>
    </p>
    <p class="p margin-b10 padding-l30">
      3、Jetpack WindowManager 是为了支持不同的可折叠设备外形规格而构建的，其设计也旨在支持未来发布的设备。为了支持未来的兼容性，系统会在 DisplayFeature（折叠屏实际类型是FoldingFeature，继承自DisplayFeature）元素的列表中将显示屏信息作为 WindowLayoutInfo 的一部分提供。此基本接口描述了显示屏的物理功能。
    </p>
    <p class="p margin-b10 padding-l30">
      FoldingFeature关键API如下：
    </p>
    <p class="p margin-b20 padding-l60">
      ● getState() ：获取展开态还是半折态，取值有FoldingFeature.State.FLAT和FoldingFeature.State.HALF_OPENED，折叠态比较特殊，目前没有折叠态的取值，折叠态会直接走onConfigurationChanged（折叠态和展开态的切换会涉及到display大小的切换，会触发应用走onConfigurationChanged；半折态和展开态由于display的大小都没变化，所以不会触发应用走onConfigurationChanged），应用如果非要获取折叠态，可以在LayoutStateChangeCallback的回调accept中根据WindowLayoutInfo获取到getDisplayFeatures()的size是0表明已经没有展开态和半折态了，即已经从展开态或者半折态切换到折叠态。
    </p>
    <p class="p margin-b10 padding-l30">
      半折态时，若应用页面支持横竖屏，则LayoutStateChangeCallback会重新回调，开发者可以获取到新的折痕位置和对折方向，以便刷新半折态时的视图。
    </p>
    <p class="p margin-b10 padding-l60">
      ● getBounds()：获取折叠屏折痕位置，上下对折时返回的区域宽大于高，比如[0, 960, 1792, 960]；左右对折时返回的区域宽小于高，比如[960, 0, 960, 1792]。
    </p>
    <p class="p margin-b20 padding-l60">
      ● getOrientation()：折叠屏对折方向，FoldingFeature.Orientation.HORIZONTAL表明是上下对折、FoldingFeature.Orientation.VERTICAL表明是左右对折。
    </p>
    <p class="p margin-b10 padding-l30">
      开发时需要在应用或模块的 build.gradle 文件中添加所需工件的依赖项：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">dependencies {</p>
        <p class="p padding-l30">
          implementation "androidx.window:window:1.1.0-alpha02"
        </p>
        <p class="p padding-l30">
          // For Java-friendly APIs to register and unregister callbacks
        </p>
        <p class="p padding-l30">
          implementation "androidx.window:window-java:1.1.0-alpha02"
        </p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      具体样例代码：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">private WindowInfoTrackerCallbackAdapter windowInfoTracker;</p>
      <p class="p ">private final LayoutStateChangeCallback layoutStateChangeCallback = new LayoutStateChangeCallback();</p>
      <p class="p ">@Override</p>
      <p class="p ">protected void onCreate(@Nullable Bundle savedInstanceState) {</p>
        <p class="p padding-l30">super.onCreate(savedInstanceState);</p>
        <p class="p padding-l30">windowInfoTracker = new WindowInfoTrackerCallbackAdapter(WindowInfoTracker.getOrCreate(this));</p>
      <p class="p ">}</p>
      <p class="p ">@Override</p>
      <p class="p ">protected void onStart() {</p>
        <p class="p padding-l30">super.onStart(); {</p>
        <p class="p padding-l30"> windowInfoTracker.addWindowLayoutInfoListener(this, Runnable::run, layoutStateChangeCallback);</p>
      <p class="p ">}</p>
      <p class="p ">@Override</p>
      <p class="p ">protected void onStop() {</p>
        <p class="p padding-l30">super.onStop();</p>
        <p class="p padding-l30">windowInfoTracker.removeWindowLayoutInfoListener(layoutStateChangeCallback);</p>
      <p class="p ">}</p>
      <p class="p ">class LayoutStateChangeCallback implements Consumer&lt;WindowLayoutInfo> {</p>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30">public void accept(WindowLayoutInfo newLayoutInfo) {</p>
          <p class="p padding-l60">// Use newLayoutInfo to update the Layout</p>
          <p class="p padding-l60">List&lt;DisplayFeature> displayFeatures = newLayoutInfo.getDisplayFeatures();</p>
          <p class="p padding-l60">if (displayFeatures.size() == 0) {</p>
            <p class="p padding-l90">// enter folded state, you can update the layout here or in onConfigurationChanged()</p>
          <p class="p padding-l60">}</p>

        <p class="p padding-l60">for (DisplayFeature feature : displayFeatures) {</p>
          <p class="p padding-l90">if (feature instanceof FoldingFeature) { </p>
            <p class="p padding-l120">if (isTableTopPosture((FoldingFeature) feature)) {</p>
              <p class="p padding-l150">enterTabletopMode(feature);</p>
            <p class="p padding-l120">} else if (isBookPosture((FoldingFeature) feature)) {</p>
              <p class="p padding-l150">enterBookMode(feature);</p>
            <p class="p padding-l120">} else if (isSeparating((FoldingFeature) feature)) {</p>
              <p class="p padding-l150">// Dual-screen device</p>
              <p class="p padding-l150">if (((FoldingFeature) feature).getOrientation() ==</p>
                  <p class="p padding-l180">FoldingFeature.Orientation.HORIZONTAL) {</p>
                  <p class="p padding-l180">enterTabletopMode(feature);</p>
              <p class="p padding-l150">} else {</p>
                  <p class="p padding-l180">enterBookMode(feature);</p>
              <p class="p padding-l150">}</p>
            <p class="p padding-l120">} else {</p>
              <p class="p padding-l150">enterNormalMode();</p>
            <p class="p padding-l120">}</p>
          <p class="p padding-l90">}</p>
        <p class="p padding-l60">}</p>
        <p class="p padding-l30">}</p>
      <p class="p ">}</p>


      <p class="p">private boolean isTableTopPosture(FoldingFeature foldFeature) {</p>
        <p class="p padding-l30">return (foldFeature != null) && (foldFeature.getState() == FoldingFeature.State.HALF_OPENED) && (foldFeature.getOrientation() == FoldingFeature.Orientation.HORIZONTAL);</p>
      <p class="p">}</p>

      <p class="p">private boolean isBookPosture(FoldingFeature foldFeature) {</p>
        <p class="p padding-l30">return (foldFeature != null) && (foldFeature.getState() == FoldingFeature.State.HALF_OPENED) && (foldFeature.getOrientation() == FoldingFeature.Orientation.VERTICAL);</p>
      <p class="p">}</p>

      <p class="p">private boolean isSeparating(FoldingFeature foldFeature) {</p>
        <p class="p padding-l30"> return (foldFeature != null) && (foldFeature.getState() == FoldingFeature.State.FLAT) && (foldFeature.isSeparating() == true);</p>
      <p class="p">}</p>

    </div>
    <p class="p margin-b10 padding-l30">
      详细信息请参阅Android开发者指南中
      <a
        class="a"
        href="https://developer.android.com/guide/topics/large-screens/make-apps-fold-aware"
      >
        <i class="el-icon-paperclip"></i>
        让应用具备折叠感知能力
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-5">
      4.5 Activity Embedding
    </h3>
    <p class="p margin-b10 padding-l30">
      大屏幕设备使用户能够看到更多内容、执行更多操作、体验更多功能。大显示屏提供了同时运行多个 activity 或同一 activity 的多个实例的机会。为了利用大屏幕的额外显示区域，Android 12L开始Jetpack WindowManager引入了Activity Embedding，该功能可以在 activity 之间拆分应用的任务窗口。
    </p>
    <p class="p margin-b10 padding-l30">
      不同于前面系统层面，不同应用间的分屏，Activity Embedding只需要对应用进行很少的重构或根本不需要对应用进行重构。您通过创建 XML 配置文件或进行 Jetpack WindowManager API 调用来确定应用如何显示其 activity（并排或堆叠）。系统会自动维护对小屏幕的支持。当应用在配备小屏幕的设备上时，activity 会相互堆叠。在大屏幕上，activity 会并排显示。系统会根据您已创建的配置（不需要分支逻辑）来确定呈现方式。
    </p>
    <p class="p margin-b10 padding-l30">
      Activity Embedding支持设备屏幕方向的变化，并且可以在可折叠设备上无缝工作，该功能会随着设备折叠和展开而堆叠和取消堆叠 activity。但如果应用由多个 activity 组成，activity 嵌入可让您轻松地在平板电脑、可折叠设备和 Chrome 操作系统设备上提供增强的用户体验。
    </p>
    <p class="p margin-b10 padding-l30">
      适配可参考文档《
      <a
        class="a"
        href="/documentsBase/activityAdapterFile"
      >
        金标联盟Activity嵌入适配指导
      </a>
      》
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-6">
      4.6 WebView页面屏幕适配
    </h3>
    <p class="p margin-b10 padding-l30">
      基于响应式Web设计来实现界面的显示和布局是目前主流的H5页面设计方式，但也存在问题。由于没有对折叠屏的窗口变化进行合理分析，可能会在展开态存在如下界面显示问题：
    </p>
    <p class="p margin-b10 padding-l30">
      ● 界面元素等比放大，显示效果差：如字体太大、图片太大/模糊等。
    </p>
    <p class="p margin-b10 padding-l30">
      ● 界面元素延展到界面外，无法操作：如广告框的关闭按钮显示在界面外，无法关闭。
    </p>
    <p class="p margin-b10 padding-l30">
      ● 界面内容重叠、错位：如输入框中文字部分显示被截断。
    </p>
    <p class="p margin-b10 padding-l30">
      为了避免出现如上基础体验问题，需要对问题界面进行调整。
    </p>
    <p class="p margin-b10 padding-l30">
      适配可参考文档
      <a
        class="a"
        href="/documentsBase/webviewAdapterFile"
      >
        《金标联盟Webview适配指导》
      </a>
      
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-7">
      4.7 FAQ
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-1">
      4.7.1 如何适配屏幕挖孔？
    </h4>
    <p class="p margin-b10 padding-l30">
      目前主流手机大多屏幕有挖孔，应用需要避开挖孔区域显示内容。
    </p>
    <p class="p margin-b10 padding-l30">
      适配挖孔，请参阅Android开发者指南中
      <a
        class="a"
        href="https://developer.android.com/guide/topics/display-cutout"
      >
        <i class="el-icon-paperclip"></i>
        支持刘海屏
      </a>
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-2">
      4.7.2 如何识别厂商折叠屏设备？
    </h4>
    <p class="p margin-b10 padding-l30">
      <b>建议应用通过支持resizeable对不同尺寸和比例的窗口进行适配，强烈不建议通过识别折叠屏的方式做特殊处理及UI适配。</b>
    </p>
    <p class="p margin-b10 padding-l30">
      若三方应用需要识别各厂商折叠屏设备，可通过以下方式识别。
    </p>
    <p class="p margin-b10 padding-l30">
      <b>注意！！！如无必要请避免针对具体设备形态进行特殊处理。</b>
    </p>
    <p class="p margin-b10 padding-l30">
      OPPO：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public static boolean isOPPOFold() {</p>
        <p class="p padding-l30">
          boolean isFold = false;
        </p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> cls = Class.forName("com.oplus.content.OplusFeatureConfigManager");</p>
          <p class="p padding-l60"> Method instance = cls.getMethod("getInstance");</p>
          <p class="p padding-l60"> Object configManager = instance.invoke(null);</p>
          <p class="p padding-l60"> Method hasFeature = cls.getDeclaredMethod("hasFeature", String.class);</p>
          <p class="p padding-l60"> Object object = hasFeature.invoke(configManager, "oplus.hardware.type.fold");</p>
          <p class="p padding-l60"> if (object instanceof Boolean) {</p>
            <p class="p padding-l90"> isFold = (boolean) object;</p>
          <p class="p padding-l60"> }</p>
        <p class="p padding-l30"> } catch (ClassNotFoundException | NoSuchMethodException | InvocationTargetException | IllegalAccessException e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return isFold;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      vivo：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">private static boolean isVivoFoldableDevice(){</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> c= Class.forName("android.util.FtDeviceInfo");</p>
          <p class="p padding-l60"> Method  m = c.getMethod("getDeviceType");</p>
          <p class="p padding-l60"> Object dType = m.invoke(c);</p>
          <p class="p padding-l60"> return "foldable".equals(dType);</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      Xiaomi：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public static boolean isXiaomiFold() {</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class c = Class.forName("android.os.SystemProperties");</p>
          <p class="p padding-l60"> Method m = c.getMethod("getInt",String.class, int.class);</p>
          <p class="p padding-l60"> int type = (int) m.invoke(c,"persist.sys.muiltdisplay_type", 0);</p>
          <p class="p padding-l60"> return type == 2;</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      适配可参考本文，4.1节
      <a  class="a" href="#4-1">
        《屏幕兼容性》
      </a>
      4.2节
      <a  class="a" href="#4-2">
        《应用连续性》
      </a>
      内容。
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-3">
      4.7.3 如何识别厂商平板设备？
    </h4>
    <p class="p margin-b10 padding-l30">
      Xiaomi：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public static boolean isXiaomiTablet() {</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class c = Class.forName("android.os.SystemProperties");</p>
          <p class="p padding-l60">  Method m = c.getMethod("get",String.class);</p>
          <p class="p padding-l60"> String type = String.valueOf(m.invoke(c,"ro.build.characteristics"));</p>
          <p class="p padding-l60"> return type.contains("tablet");</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      vivo：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">private static boolean isVivoTablet(){</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> c= Class.forName("android.util.FtDeviceInfo");</p>
          <p class="p padding-l60"> Method  m = c.getMethod("getDeviceType");</p>
          <p class="p padding-l60"> Object dType = m.invoke(c);</p>
          <p class="p padding-l60"> return "tablet".equals(dType);</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    
    <p class="p margin-b10 padding-l30">
      OPPO：
    </p>
    <div class="code margin-b20  margin-l30">
      <p class="p ">public static boolean isOPPOTablet() {</p>
        <p class="p padding-l30">
          boolean isTablet = false;
        </p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> cls = Class.forName("com.oplus.content.OplusFeatureConfigManager");</p>
          <p class="p padding-l60"> Method instance = cls.getMethod("getInstance");</p>
          <p class="p padding-l60"> Object configManager = instance.invoke(null);</p>
          <p class="p padding-l60"> Method hasFeature = cls.getDeclaredMethod("hasFeature", String.class);</p>
          <p class="p padding-l60"> Object object = hasFeature.invoke(configManager, "oplus.hardware.type.tablet");</p>
          <p class="p padding-l60"> if (object instanceof Boolean) {</p>
            <p class="p padding-l90">  isTablet = (boolean) object;</p>
          <p class="p padding-l60"> }</p>
        <p class="p padding-l30"> } catch (ClassNotFoundException | NoSuchMethodException | InvocationTargetException | IllegalAccessException e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return isTablet;</p>
      <p class="p ">}</p>
    </div>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-4">
      4.7.4 如何识别折叠屏悬停状态？
    </h4>
    <p class="p margin-b10 padding-l30">
      悬停状态，实际上是屏幕半折态，仍然处于大屏下，vivo(从Android 12L开始)、OPPO和小米（从Android T版本开始）均支持google的JetPack去感知展开态和半折态和半折态区域，用于应用监听半折态做悬停布局。
    </p>
    <p class="p margin-b10 padding-l30">
      可参考本文4.4.4节
      <a  class="a" href="#4-4-4">
      《折叠屏状态监听和获取》
      </a>
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-5">
      4.7.5 当应用未接入AndroidX库时，如何感知折叠屏悬停状态？
    </h4>
    <p class="p margin-b10 padding-l30">
      可参考文档
      <a
        class="a"
        href="/documentsBase/androidXOnHoverFile"
      >
        《金标联盟android T 无androidX实现悬停监听》
      </a>
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-6">
      4.7.6 如何同时兼容Activity Embedding与厂商自研平行视窗？
    </h4>
    <p class="p margin-b10 padding-l30">
      当应用同时接入Activity Embedding与厂商自研平行视窗时，系统会根据ROM的版本优先支持原生的Activity Embedding，如果ROM版本不支持Activity Embedding，则支持自研的平行视窗。
    </p>
    <p class="p margin-b10 padding-l30">
      此时应用需要在manifest中添加如下标识，并指定为 false 。
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">&lt;application></p>
        <p class="p padding-l30">
          &lt;property android:name="android.window.PROPERTY_ACTIVITY_EMBEDDING_ALLOW_SYSTEM_OVERRIDE" android:value="false" />
        </p>
      <p class="p ">&lt;/application></p>
    </div>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="5">
      五、 开发、调试工具
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-1">
      5.1 通过模拟器调试
    </h3>
    <p class="p margin-b10 padding-l30">
      目前Android Studio已经提供了异形屏的虚拟设备支持，可以直接使用预设的虚拟设备进行调试，现在提供了左右内折、左右外折、Rollable、上下折叠等形式的预设，均支持动态切换状态（目前无法自定义分辨率）。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image84.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image84.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      启动虚拟设备可以看到，异形屏设备的工具栏提供了屏幕形态切换及设备方向旋转等功能，可以通过这种方法，动态的对应用在异形屏设备上的各种状态进行调试（转屏、大小屏切换、半折）。
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image85.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image85.png',
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-2">
      5.2 对于不同分辨率应单独调试
    </h3>
    <p class="p margin-b10 padding-l30">
      <b>值得注意的是：对于不同分辨率的异形屏设备而言仍然应该使用自定义分辨率的设备进行调试，以确保实际的显示效果</b>
    </p>
    <p class="p margin-b10 padding-l30">
      假设新型态项⽬设备屏幕密度⽐dpi是440，分辨率为1920x1700，开发者可以通过模拟器创建相近尺⼨的屏幕进⾏调试开发、或者可以用一个大屏设备通过命令指定分辨率和密度。
    </p>
    <p class="p margin-b10 padding-l30">
      应用开发者可以使用命令模拟分辨率和密度：
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm size 1920x1700      //尺寸切换到1920x1700
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm density 440            //density切换到440
    </p>
    <p class="p margin-b10 padding-l30">
      复位使用命令：
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm size reset
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm density reset
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-3">
      5.3 测试用例建议
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-1">
      5.3.1 用例1
    </h4>
    <p class="p margin-b10 padding-l30">
      应⽤在新型态项⽬下⻚⾯显示正常
    </p>
    <p class="p margin-b10 padding-l30">
      测试步骤：
    </p>
    <p class="p margin-b10 padding-l30">
      1）在新型态项⽬下，打开应⽤，查看应⽤在横竖屏场景下各个⻚⾯显示效果
    </p>
    <p class="p margin-b10 padding-l30">
      预期结果：
    </p>
    <p class="p margin-b10 padding-l30">
      1）应⽤的所有⻚⾯可以全屏显示，⻚⾯没有发⽣截断、拉伸变形、放⼤模糊，按钮缺失等问题
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-2">
      5.3.2 用例2
    </h4>
    <p class="p margin-b10 padding-l30">
      应⽤在新型态项⽬下核⼼⻚⾯功能正常
    </p>
    <p class="p margin-b10 padding-l30">
      测试步骤：
    </p>
    <p class="p margin-b10 padding-l30">
      1）在新型态项⽬下，打开应⽤；
    </p>
    <p class="p margin-b10 padding-l30">
      2）在横竖屏场景下遍历应⽤核⼼⻚⾯的控件按钮
    </p>
    <p class="p margin-b10 padding-l30">
      预期结果：
    </p>
    <p class="p margin-b10 padding-l30">
      1） 应⽤在新型态屏幕下，所有功能按钮可⽤，⽆失效/crash/anr等问题
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-3">
      5.3.3 用例3
    </h4>
    <p class="p margin-b10 padding-l30">
      应⽤在分辨率变化时核⼼⻚⾯显示正常
    </p>
    <p class="p margin-b10 padding-l30">
      测试步骤：
    </p>
    <p class="p margin-b10 padding-l30">
      1）在新型态项⽬下，打开应⽤，然后用命令切换一个不同的直板机分辨率，比如1080x1920
    </p>
    <p class="p margin-b10 padding-l30">
      预期结果：
    </p>
    <p class="p margin-b10 padding-l30">
      1）应⽤的所有⻚⾯可以全屏显示，⻚⾯没有发⽣截断、拉伸变形、放⼤模糊，按钮缺失等问题
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-4">
      5.3.4 用例4
    </h4>
    <p class="p margin-b10 padding-l30">
      应⽤在分辨率变化时核⼼⻚⾯功能正常
    </p>
    <p class="p margin-b10 padding-l30">
      测试步骤：
    </p>
    <p class="p margin-b10 padding-l30">
      1）在新型态项⽬下，打开应⽤，然后用命令切换一个不同的直板机分辨率，比如1080x1920
    </p>
    <p class="p margin-b10 padding-l30">
      2）遍历应⽤核⼼⻚⾯的控件按钮
    </p>
    <p class="p margin-b10 padding-l30">
      预期结果：
    </p>
    <p class="p margin-b10 padding-l30">
      1） 应⽤在切换分辨率后，所有功能按钮可⽤，⽆失效/crash/anr等问题
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-5">
      5.3.5 用例5
    </h4>
    <p class="p margin-b10 padding-l30">
      进入应用的小窗和分屏模式，应用的界面显示和功能交互均正常 
    </p>
    <p class="p margin-b10 padding-l30">
      测试步骤：
    </p>
    <p class="p margin-b10 padding-l30">
      1）进入应用的小窗或分屏模式
    </p>
    <p class="p margin-b10 padding-l30">
      2）观察页面显示是否正常
    </p>
    <p class="p margin-b10 padding-l30">
      3）依次点击各个页面的按钮，观察功能交互是否正常
    </p>
    <p class="p margin-b10 padding-l30">
      预期结果：
    </p>
    <p class="p margin-b10 padding-l30">
      1）应用在小窗和分屏模式下，没有任何页面变形、截断、模糊、缺失等问题
    </p>
    <p class="p margin-b10 padding-l30">
      2）应用的所有页面按钮均可以正常响应，没有任何无反应/crash/anr等问题
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="6">
      六、 附录
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="6-1">
      6.1 厂商产品规格介绍
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="6-1-1">
      6.1.1 折叠手机
    </h4>
    <p class="p margin-b10 padding-l30">
      OPPO Find N：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image86.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image86.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      主屏：7.1 英寸，1792 × 1920像素，8.4:9
    </p>
    <p class="p margin-b10 padding-l30">
      副屏：5.49英寸，1972 × 988像素，18:9
    </p>
    <p class="p margin-b30 padding-l30">
      density: 440
    </p>
    <p class="p margin-b10 padding-l30">
      vivo X Fold:
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image87.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image87.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      主屏：8.03英寸，2160 × 1916像素，4:3.55
    </p>
    <p class="p margin-b10 padding-l30">
      副屏：6.53英寸，2520 × 1080像素，21:9
    </p>
    <p class="p margin-b30 padding-l30">
      density: 480
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi MIX FOLD：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image88.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image88.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      主屏：8.01 英寸，2480 × 1860像素，4:3
    </p>
    <p class="p margin-b10 padding-l30">
      副屏：6.52英寸，2520 × 840像素，27:9
    </p>
    <p class="p margin-b20 padding-l30">
      density: 440
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi MIX Fold 2：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image89.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image89.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      主屏：8.02英寸，2160 × 1914，4:3.55
    </p>
    <p class="p margin-b10 padding-l30">
      副屏：6.56英寸，2520 × 1080，21:9
    </p>
    <p class="p margin-b20 padding-l30">
      density: 440
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="6-1-2">
      6.1.2 平板
    </h4>
    <p class="p margin-b10 padding-l30">
      小米平板：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image90.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image90.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      小米平板5: 11英寸，2560x1600，16:10，density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      小米平板5 Pro: 11英寸，2560x1600，16:10，density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      小米平板5 Pro 5G: 11英寸，2560x1600，16:10，density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      小米平板5 Pro 12.4: 12.4英寸，2560x1600，16:10，density: 320
    </p>
    <p class="p margin-t30 margin-b10 padding-l30">
      OPPO平板：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image91.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image91.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      OPPO Pad: 11英寸，2560 × 1600，16:10，density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      OPPO Pad Air: 10.36英寸，2000 × 1200，5:3，density: 280
    </p>
    <p class="p margin-t30 margin-b10 padding-l30">
      vivo平板：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image92.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image92.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      vivo Pad: 11英寸，2560 × 1600，16:10，density: 400
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="6-2">
      6.2 厂商适配指导
    </h3>
    <p class="p  padding-l30">
      OPPO适配指导：
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://open.oppomobile.com/new/developmentDoc/info?id=11308" target="_blank">
        https://open.oppomobile.com/new/developmentDoc/info?id=11308
      </a>
    </p>
    <p class="p  padding-l30">
      vivo适配指导：
    </p>
    <p class="p padding-l30">
      <a class="a"  href="https://dev.vivo.com.cn/documentCenter/doc/597" target="_blank">
        https://dev.vivo.com.cn/documentCenter/doc/597
      </a>
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://dev.vivo.com.cn/documentCenter/doc/616" target="_blank">
        https://dev.vivo.com.cn/documentCenter/doc/616
      </a>
    </p>
    <p class="p  padding-l30">
      Xiaomi适配指导：
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://dev.mi.com/console/doc/detail?pId=2768" target="_blank">
        https://dev.mi.com/console/doc/detail?pId=2768
      </a>
    </p>
    
    
  </div>
</template>

<script>
export default {
  props: ['scrollContainer'],
  data() {
    return {
    };
  },
  created() {
    
  },
};
</script>

<style scoped lang="scss">
.h1 {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p {
  display: block;
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
.a {
  color: rgb(64, 158, 255);
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>
