<template>
  <div class="view-box">
    <h1 class="h1 text-center ">
      ITGSA Large Screen Device Application Adaptation White Paper
    </h1>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="1">
      一、Introduction
    </h2>
    <p class="p margin-b10">
      This document is ITGSA's adaptation guide for mobile phone Apps for large screens such as foldable screens and tablets, covering display adaptation, interface layout guide and development guidance, etc. to guide designers and developers to adapt their Apps quickly to large screen devices, and maintain consistent and excellent user experience on OPPO, vivo, Xiaomi and large screen devices of other brands.
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-1">
      1.1 Shape of device screen
    </h3>
    <p class="p margin-b10 padding-l30">
      <b>● Straight-screen mobile phone: </b>the most common form of mobile phone at present, having a fixed screen.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Foldable-screen mobile phone: </b>Mobile phone produced based on flexible screen technology, supporting folding and changing form. It generally has two screens (internal and external screens), with multiple use postures.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Tablet device: </b>mobile terminal with larger screen size, generally more than 7 inches.
    </p>
    <p class="margin-b10">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image1.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image1.png',
        ]"
      >
      </el-image> -->
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image1.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image1.png'),
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-2">
      1.2 Use posture of foldable screen devices
    </h3>
    <p class="p margin-b10">
      Different from ordinary straight-screen mobile phones, flexible screens are used for foldable-screen devices, and they support bending and folding of the device body and screen, so they have more use postures. Generally, there are three use postures of foldable screen devices:
    </p>
    <p class="margin-b10">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image2.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image2.png',
        ]"
      >
      </el-image> -->
      
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image2.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image2.png'),
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Folded state：</b>The state after device is folded. During this state, the device has moderate screen size, and the user experience is similar to that of ordinary straight-screen mobile phones.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Flat state：</b>The state after device body is fully expanded. During this state, the screen of the device is large, and the user experience is similar to that of a tablet device.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Half-opened state：</b>also known as hover state, a state in which the screen is not fully expanded. During this state, the device can be erected like a book, or placed stably like a laptop, hovering to display content.
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="2">
      II. Adaptation principles
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-1">
      2.1 Experience continuity
    </h3>
    <p class="p margin-b10 padding-l30">
      <b>● Cross-device experience continuity：</b>
    </p>
    <p class="p margin-b10 padding-l30">
      Users may have different devices at the same time. Even same device, such as a Foldable-screen phone, may have multiple screens of different sizes. When the same App runs on different screens, interface layout and design style should be related and of continuity so as to bring users consistent cognition and similar user experience.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Cross-screen operation continuity：</b>
    </p>
    <p class="p margin-b10 padding-l30">
      Foldable-screen phones have diverse use postures, and users often switch the device between these postures. When display orientation and screen size change, the App should be able to change the layout in a real-time manner, and retain the user's browsing location or application status, so that the user can continue previous work.
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="2-2">
      2.2 Experience improvement
    </h3>
    <p class="p margin-b10">
      On larger screen, users should get some user experience improvement. Generally, the improvement is mainly embodied in the following aspects:
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● More content：</b>With larger display space, Apps can present more content, elevate the efficiency of users' access to information and reduce the frequency of users' turning pages or scrolling screen for view.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Better immersion：</b
      >For pictures, videos, games and other content, the Apps can utilize the benefits of screen size to display part of the content much larger and clearer, so that users gain a stronger sense of immersion while browsing.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>● Higher efficiency：</b>When screen space is adequate, Apps should make full use of the extra space to further elevate user efficiency. For example, the content of next level of pages can be displayed in current page, reducing frequency of users in jumping back and forth between pages; or support cross-App drag and drop in multi-window state, so that users can share content to other Apps more quickly.
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="3">
      III. Design Guidelines
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="3-1">
      3.1 Adaptation method
    </h3>
    <p class="p margin-b10">
      The mobile devices of users are from a variety of brands, and the screen size and ratio are different. In order to make the App to offer a comfortable, consistent and convenient experience on all devices through one adaptation, we recommend that developers design according to the following steps:
    </p>
    <p class="p margin-b10 padding-l30">
      1） Display interface elements in appropriate size.
      <a  class="a" href="#3-1-1">
        (See 3.1.1 Adjustment of display size)
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      2） Build a responsive application interface.
      <a  class="a" href="#3-1-2">
        (See 3.1.2 Responsive layout and foundation adaptation)
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      3） Design different layouts for different screen sizes.
      <a  class="a" href="#3-1-3">
        (See 3.1.3 Breakpoints and adaptive layout)
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      4） Further improve interactive experience from large screen devices.
      <a  class="a" href="#3-1-4">
        (See 3.1.4 Optimal interactive experience)
      </a>
    </p>
    <p class="p margin-b10 padding-l30">
      5） Support multi-task scenarios
      <a  class="a" href="#3-1-5">
        (see 3.1.5 Multi- task and multi -window)
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b10 nav-title" id="3-1-1">
      3.1.1 Adjustment of display size
    </h4>
    <p class="p margin-b20">
      Due to large difference in screen ratio and size, directly magnifying and displaying interface of a straight-screen mobile phone on a foldable screen or tablet device will generally lead to excessive content size, lower user experience, and even affect normal use of some interfaces. For adaptation, please adjust display size of content according to following principles and guidelines.
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-1-1">
      3.1.1.1 Integrity of display
    </h5>
    <p class="p margin-b10">
      Under any circumstance, all interfaces of the application should be complete and available. When running on a large screen, please ensure that your application meets at least following basic requirements:
    </p>
    <p class="p margin-b10 padding-l30">
      ● The interface will fill the screen in normal state. The content will not be stretched, deformed or cut;
    </p>
    <p class="p margin-b10 padding-l30">
      ● Pictures, videos, promotion pop-ups and other contents can be fully displayed on the screen;
    </p>
    <p class="p margin-b10 padding-l30">
      ● Text content can be read normally，having no overlapping;
    </p>
    <p class="p margin-b20 padding-l30">
      ● All functional buttons can always operate normally.
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-1-2">
      3.1.1.2 Adaptation with magnification
    </h5>
    <p class="p margin-b20">
      Because screens of different devices have different pixel densities, it is not appropriate to scale interface according to screen size or pixel width during adaptation. To ensure that application interface is comfortable and easy to use on all devices, repeated adaptation for new devices should be avoided. We recommend that magnification should be used to confirm appropriate display size of various elements.
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Magnification
    </h5>
    <p class="p margin-b10 padding-l30">
      The magnification means the magnification of various interface elements on current screen relative to benchmark design size. It is an inherent display parameter of corresponding screen. Its value is only affected by option of system 【Display Size】. We set an appropriate magnification for each screen. Under the magnification, the App elements will be displayed in a reasonable size on current screen, ensuring a comfortable user experience.
    </p>
    <p class="p margin-b10 padding-l30">
      You can obtain the magnification of current screen in following ways:
    </p>
    <div class="code margin-b10 margin-l30">
      <p class="p ">float density = getResources().getDisplayMetrics().density</p>  
    </div>
    <p class="p margin-b20 padding-l30">
      In the process of folding/expansion of foldable screen, screen switching may cause the change of magnification. We recommend that developers always obtain current magnification when refreshing interface layout.
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Adaptation through magnification
    </h5>
    <p class="p margin-b20 padding-l30">
      The magnification is the magnification value relative to basic design size. You can draw application interface on canvas with a basic design width of 360dp and determine basic design dimensions of visual elements, and multiply with obtained magnification value directly during display.
    </p>
    <p class="p margin-b20 padding-l30">
      If you have finished design on a canvas of another size, you can scale your application interface to 360dp width with equal ratio, calculate basic design dimensions of each element, and then multiply them with the obtained magnification value. You can observe the change of display size in this process through the round button in the lower interface.
    </p>
    <p class="p margin-b20 padding-l30">
      After adaptation in this way, the App does not need to adjust the display size of interface elements for screen of new device. Generally, the App only needs to perform this conversion calculation for fixed size, spacing, margin, font size and other values. The rules for proportional dimensions or responsive fills remain unchanged.
    </p>
    <p class="margin-b10 padding-l30">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image4.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image4.png',
        ]"
      >
      </el-image> -->
      
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image4.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image4.png'),
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      You should also check your embedded HTML5 content and adjust it in the same way.
    </p>
    <p class="p-small margin-b20 padding-l30">
      <b>If your App cannot use the magnification for adaptation, try to keep display size of various elements consistent on different screens. Icons and fonts should not have distinct size change on large screen.</b>
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-1-3">
      3.1.1.3 Display size of pictures, videos and other elements
    </h5>
    <p class="p margin-b20">
      Pictures and videos have a fixed length-width ratio. According to different types and display mode, we recommend different adaptation logic should be used in different scenarios:
    </p>
    <p class="p margin-b10 padding-l30">
      1）When displaying startup picture, please do not stretch the picture resources of straight-screen phone horizontally. It is recommended to replace the pictures with the ones with larger size, and fill the screen fully in any case.
    </p>
    <p class="margin-b10 padding-l30">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image5.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image5.png',
        ]"
      >
      </el-image> -->
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image5.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image5.png'),
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      2）If the picture is displayed in a grid or waterfall mode, it is needed to control display size of elements by increasing the number of display columns on the larger screen.  The situation that the screen becomes larger but the information presented decreases should be avoided.
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image6.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image6.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      3）If in content stream, the content of pictures and videos is not limited by cards or containers, it is recommended to use magnification to identify display size, or always keep it similar to the display size of a straight-screen phone, so as to ensure the total information in a screen does not decrease.
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image7.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image7.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      4）For Banner element that fills screen horizontally, it is recommended to separate background and content, and only enlarge background element at same height. The content elements should be adapted using the magnification, or keep original size unchanged.
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image8.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image8.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      5）Support rotary pictures scrolling and switching horizontally. It is recommended to maintain the same height as that in straight-screen mobile phone, and increase the display of upper and lower pictures in the left and right margins to fill extra screen space.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image9.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image9.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-1-2">
      3.1.2 Responsive layout and foundation adaptation
    </h4>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-2-1">
      3.1.2.1 Definition
    </h5>
    <p class="p margin-b10">
      Responsive Layout refers to the layout that enables page elements to adjust their positions in a real-time manner according to changes in screen size, allowing the elements to dynamically adapt screens of different sizes like water. When the interface is adjusted as a responsive layout, your App can automatically implement basic adaptation on all devices.
    </p>
    <p class="p margin-b10">
      You can build a responsive layout by setting constraints. For example, when a button is set to be " 40dp from the bottom of the screen and centered ", the button will maintain this alignment rule on any screen size. Constraints can be based on screen edges, container edges, or other element boundaries.
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-2-2">
      3.1.2.2 Basic types of responsive layout
    </h5>
    <p class="p margin-b10">
      Common responsive layouts include: Relative fixation, Relative scaling, Relative stretching, and content extension. You can set constraints between interface elements in these modes.
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Relative fixation
    </h5>
    <p class="p margin-b10 padding-l30">
      Relative fixation means that the position of an element is determined by a reference, such as a screen edge, window edge, or another element. When screen size changes, the position of the elements remains relatively fixed.
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>Applicable scenarios：</b>Hover button, bottom navigation bar, title bar, paging TAB, avatar, etc.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image10.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image10.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Relative stretching
    </h5>
    <p class="p margin-b10 padding-l30">
      Relative stretching generally means that the height of an element in the page is fixed, and the width is determined by reference. When the page becomes wider, the width of the element or the spacing between multiple elements becomes wider.
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>Applicable scenarios：</b>Text content, search box, input box, card container, etc.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image11.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image11.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Relative scaling
    </h5>
    <p class="p margin-b10 padding-l30">
      Relative scaling means that a fixed ratio is maintained for elements in the page, and size is determined by comparing with reference. When the screen becomes wider, the elements will become larger proportionally.
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>Applicable scenarios：</b>only applicable to media in full screen or in immersive browsing state, or pictures, videos and other contents in card containers.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image12.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image12.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Content Extension
    </h5>
    <p class="p margin-b10 padding-l30">
      Content extension means that when the screen becomes wider, more elements will be exposed like a scroll.
    </p>
    <p class="p margin-b20 padding-l30" style="font-size:15px;">
      <b>Applicable scenarios：</b>Horizontal element groups with fixed size and spacing; generally support left and right sliding, such as information TAB, content recommendation cards, visual data blocks, etc.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image13.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image13.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-2-3">
      3.1.2.3 Check responsiveness of all contents
    </h5>
    <p class="p margin-b10">
      You may have embedded HTML5 content in some pages, or used HTML5 pages as a whole.
    </p>
    <p class="p margin-b10">
      In the process of adaptation, in addition to adjusting the pages in the App, you should also check the embedded content at the same time to ensure that the App can implement complete responsive changes.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image14.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image14.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4  margin-t20 margin-b10 nav-title" id="3-1-3">
      3.1.3 Breakpoint and adaptive layout
    </h4>
    <p class="p margin-b10">
      Adaptive Layout refers to creating multiple layouts for different types of screens and selecting corresponding layouts according to screen size type. When the screen size crosses a boundary value, the page layout will be automatically changed.
    </p>
    <p class="p margin-b10">
      Building the breakpoint adaptive layout can elevate information density on the large screen, or further improve display effect and user experience of the App.
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-3-1">
      3.1.3.1 Breakpoint and grid
    </h5>
    <h5 class="h5  margin-b10 padding-l30">
      Breakpoint
    </h5>
    <p class="p margin-b10 padding-l30">
      The breakpoint is the boundary value of width or height set manually.
    </p>
    <p class="p margin-b10 padding-l30">
      With breakpoints, designers and developers can divide screens with different sizes and ratios into a limited number of types, and design layouts only for each of these types. With responsive constraints, Apps can get a better user experience on all screens at a very low cost.
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Breakpoint value
    </h5>
    <p class="p margin-b10 padding-l30">
      Most of App pages are portrait layout, that is, vertical content is much more than horizontal content, and the content is mainly viewed by scrolling up and down. Therefore, when size of screen or window changes, it is generally necessary to pay more attention to the impact of width on layout. The breakpoints described below also mainly refer to width breakpoints.
    </p>
    <p class="p margin-b10 padding-l30">
      In order to ensure that no additional adaption is needed for the App when it is released overseas, we recommend that developers use the same breakpoint value as Material Design, and also divide screens into three types: Compact, Medium, and Expanded for design.
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image15.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image15.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p  padding-l30">
     You can find breakpoint values and definition about Material Design here:
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://m3.material.io/foundations/adaptive-design/large-screens/overview">
        https://m3.material.io/foundations/adaptive-design/large-screens/overview
      </a>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Grid
    </h5>
    <p class="p margin-b10 padding-l30">
      The Grid is an aid to the horizontal layout of elements. It guides distribution of the elements with regular number of columns, margins and spacing.
    </p>
    <p class="p margin-b20 padding-l30">
      Using grid for layout can make element arrangement look orderly, help App presents similar layouts, or presents clearer development rules on different devices.
    </p>
    <h5 class="h5  margin-b20 padding-l30">
      Composition of grid
    </h5>
    <p class="p margin-b10 padding-l30">
      The grid consists of margins, columns, and gutters.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Margin: </b>It is used to control the relations of distance between elements and the outermost edge of the screen. Different values can be defined according to different sizes of devices as unified specification in the breakpoint system. The margin varies according to window size and hardware limits.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Column:</b>It is the main positioning tool used to assist layout. Different screen sizes are matched with different columns to assist layout positioning. The width of each column is calculated automatically according to the width of actual window and number of columns, provided that the margin and gutter meet given specifications.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Gutter:</b>It is used to control the distance relations between elements. Different gutter values can be defined according to different sizes of devices as unified specification in the breakpoint system.  In order to ensure a better visual effect, the value of gutter is usually not greater than the value of margin. The size of gutter is generally fixed.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image16.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image16.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Application of grid system
    </h5>
    <p class="p margin-b10 padding-l30">
      The number of columns of the grid depends on the width breakpoint to which it belongs. The larger the screen, the more columns are needed to subdivide the layout.
    </p>
    <p class="p margin-b10 padding-l30">
      The number of columns of the grid is related to the breakpoint range of the screen/window width. It is recommended to use 4 columns for compact-screen device, 8 columns for medium screen and 12 columns for expanded screen.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image17.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image17.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      For separate windows or pages, such as split screen, panels, and expansion levels, the number of breakpoints and columns of the grid shall be determined according to the width of each page.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image18.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image18.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      When side navigation is expanded on expanded screen, the right page is temporarily squeezed; original number of the columns should be kept unchanged.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image19.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image19.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-3-2">
      3.1.3.2 Basic types of breakpoint adaptive layouts
    </h5>
    <p class="p margin-b10">
      Common breakpoint adaptive layouts include indented layout, more column layout, and move layout.
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Indented Layout
    </h5>
    <p class="p margin-b20 padding-l30">
      When single-column content is displayed, a larger screen will make the intimacy between the list and the assigned content unclear, which makes it difficult for users to accurately correspond the content on both sides. Typically, the content needs to be indented, with fewer columns.
    </p>
    <p class="margin-b20 padding-l30">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image20.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image20.png',
        ]"
      >
      </el-image> -->
      
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image20.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image20.png'),
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      There is no need of indenting on compact screen with 4 -columns. It is recommended to indent 2 columns on the medium screen with 8 columns, and only use the middle 6 columns. It is recommended to indent 4 columns on the expanded screen with 12 columns, and only use the middle 8 columns.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image21.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image21.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      Similarly, in reading scenario, excessive horizontal span of text will lead to difficulty in reading, which is likely to lead to fatigue and reduce information acquisition effect. On expanded screen, it is recommended to indent text content as well.
    </p>
    <p class="margin-b20 padding-l30">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image22.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image22.png',
        ]"
      >
      </el-image> -->
      
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image22.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image22.png'),
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      More column layout
    </h5>
    <p class="p margin-b20 padding-l30">
      For vertically arranged cards, grids or waterfall content, more columns need to be displayed on an expanded screen to control the size of the container and content, or use the increased width to display more information.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image23.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image23.png',
        ]"
      >
      </el-image>
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image24.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image24.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Move Layout
    </h5>
    <p class="p margin-b20 padding-l30">
      When screen size crosses the breakpoint or the ratio changes, the layout of some modules can be moved to improve utilization efficiency of the screen.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image25.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image25.png',
        ]"
      >
      </el-image>
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image26.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image26.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b10 nav-title" id="3-1-3-3">
      3.1.3.3 Deep customization layout
    </h5>
    <p class="p margin-b10">
      After crossing breakpoint, the layout of the App is completely changed. Developers can freely do what they want to do. They can conduct deeper design and customization, display richer and more hierarchical information, or show more menus or content to improve user efficiency.
    </p>
    <p class="p margin-b20">
      Similarly, a unique interface can be customized for special use of some devices to further expand use posture. The following are some methods of customizing layout. We encourage developers to seek more approaches to bring better user experience.
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Expansion of levels 
    </h5>
    <p class="p margin-b20 padding-l30">
      The jump of page may lead to the change of the whole page content. The operation efficiency is low. Furthermore, users are more likely to forget their position and the process progress. On a larger screen, users are more likely to lose their visual focus during the jump. The issue is more obvious. With the space of large screen, the App can expand and display the content of the next level in the interface of the upper level, reduce the frequency of refreshing the whole page and improve browsing efficiency.
    </p>
    <p class="p margin-b20 padding-l30">
      The common way to extend level is to build parent-child and column layouts. The parent-child interface is applicable to main interface of IM, note, mailbox and other list applications while column layout is applicable to social content page, music play page and other detail pages with relatively fixed content at the next level.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image27.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image27.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Expansion of functions 
    </h5>
    <p class="p margin-b20 padding-l30">
      There is sufficient space on large screen. Applications can reduce menu levels, directly present more functions in the interface to achieve a functional layout similar to desktop and elevate user operation efficiency.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image28.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image28.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5  margin-b10 padding-l30">
      Hover adaptation
    </h5>
    <p class="p margin-b20 padding-l30">
      Foldable-screen product has a unique hovering bracket state. Users can stand the product on desktop after half-folding to achieve stable use after the product leaves the hand. The application can use this state to offer video playback, stable shooting and other functions.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image29.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image29.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p  padding-l30">
      In hover state, the screen generally has three areas:
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image30.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image30.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p-small margin-b10 padding-l30">
      <b class="padding-l30">①：Operation can be made with fingers comfortably in this area;</b>
    </p>
    <p class="p-small margin-b10 padding-l30">
      <b class="padding-l30">②：Fingers can reach this area comfortably, but the device is more likely to lose balance caused by operation;</b
      >
    </p>
    <p class="p-small margin-b20 padding-l30">
      <b class="padding-l30">③：Area not beyond about 30dp from the shaft. It is relatively easy for fingers to reach this area, but it is difficult to operate accurately within this area due to the influence of folding and hovering angle.</b
      >
    </p>
    <p class="p margin-b20 padding-l30">
      In holding and placing state, the most comfortable operation hot-area of fingers is mainly in the lower half of the screen, while the upper half area has more friendly viewing angle. When designing hover layout, App should move display content up and operation content down, set all buttons in area ① if permitted and avoid placing any interactive elements in area ③.
    </p>
    <p class="p margin-b20">
      
      <a class="a"  href="#4-4-4">
        Please refer to Section 4.4.4 F Foldable Screen State Listen and Acquisition for relevant development document.
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b10 nav-title" id="3-1-4">
      3.1.4 Optimal interactive experience
    </h4>
    <p class="p margin-b20">
      When using a foldable-screen or tablet device, a user's holding posture, input mode and use scenario are usually different from those of using a straight-screen phone. When designing a large screen layout, above-mentioned factors may need to be considered to further optimize interactive experience and usability of the application.
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-4-1">
      3.1.4.1 Optimization for foldable screen
    </h5>
    <h5 class="h5  margin-b10 padding-l30">
      Device use posture
    </h5>
    <p class="p margin-b10 padding-l30">
      In folded state, foldable-screen phones are usually similar to ordinary phones in size and use posture. Generally, it is only necessary to consider the slight impact of the increased thickness on holding and operation, and there is no need to adjust  interface layout for this state;
    </p>
    <p class="p margin-b10 padding-l30">
      After expansion, the size of the device becomes larger, and user experience is closer to that of tablet. It is difficult for users to complete all operations with one hand. The common use postures are as follows:
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Hold the device with both hands for use;
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Hold the device with one hand and operate it with the other hand;
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Hold the device with one hand to watch media content;
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Place the device on desktop or stable surface for operation and use;
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b>
      Watch media content for a long time in hovering mode or with support of bracket for placement.
    </p>
    <p class="p margin-b20 padding-l30">
      Among the above-mentioned postures, holding with both hands is one of the main operating postures after the foldable screen is expanded. In this mode, there are areas with poor operation experience on the screen. It is recommended to adjust application interface by referring to the hot area for holding operation.
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Hot area for holding operation
    </h5>
    <p class="p padding-l30">
      When using foldable screen with both hands, the screen is generally divided into the following four areas according to the touch range of fingers:
    </p>
    <p class="">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image31.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image31.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">①：The area can be reached by fingers comfortably; </b>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">②：The area can be reached by stretching fingers, but it is slightly difficult; </b>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">③：The area can be reached by bending fingers, but it is relatively difficult. It is basically impossible to operate the device in this area in a single-hand holding posture;</b>
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">④：It is difficult to reach this area without changing holding mode.</b>
    </p>
    <p class="p margin-b20 padding-l30">When designing the layout of foldable screen for flat state, the coverage of each area should be fully considered to avoid arranging buttons needing to be frequently operated in areas that are difficult to reach with fingers. </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-4-2">
      3.1.4.2 Optimization for pad
    </h5>
    <h5 class="h5 margin-b10 padding-l30">
      Device use posture 
    </h5>
    <p class="p margin-b10 padding-l30">
      The common use postures of tablet devices are as follows:
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Hold the device with one hand and operate it with the other hand;
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Place the device on lap or stable surface for operation and use;
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Watch media content for a long time with support of bracket for placement.
    </p>
    <p class="p margin-b10 padding-l30">
      <b class="padding-l30">● </b> Hold the device with both hands for shooting.
    </p>
    <p class="p margin-b10 padding-l30">
      In the above use postures, except for special scenarios such as shooting, there is no obvious area for difficult operation on the screen. However, due to the large size of screen, the elements at the edge of the screen are relatively unobvious and difficult to be noticed by users. It is recommended that the content should be properly concentrated in the center of the screen during design.
    </p>
    <p class="p margin-b20 padding-l30">
      In shooting scenario, considering that the user will hold the device with both hands, it is recommended to place the functional buttons on the left and right edge areas of the middle and lower part of the screen to avoid placing key operations at the top and bottom central areas of the screen.
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Adaptation based on input device
    </h5>
    <p class="p margin-b20 padding-l30">
      There are more input methods for tablet devices, including but not limited to keyboard, mouse, touch pad, handwriting pen, voice, etc. During adaptation, the application interface may need to be adjusted for these input devices, or corresponding functions and interaction styles need to be added. For example, add hover and right click state for mouse operation, and add adjustment panel for handwriting pen.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image32.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image32.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-4-3">
      3.1.4.3 Optimize interface and components for expanded screen
    </h5>
    <h5 class="h5 margin-b10 padding-l30">
      Using modal components to host temporary tasks
    </h5>
    <p class="p margin-b10 padding-l30">
      When there is enough screen space, the application can use modal component to carry some simple temporary tasks, such as creating, selecting, setting and content input, so that users can complete some processes without leaving core page perceptively;
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image33.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image33.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Optimizing pop-up layer
    </h5>
    <p class="p margin-b10 padding-l30">
      On a large screen, the pop-up layer that fills the screen space will lead to a stronger sense of occlusion, and may cause problems such as unreadable text, difficult matching options, and reduced button availability. When pop-ups, assignment panels and other components are displayed on large screen, it is recommended that they should be displayed in a floating style, and maximum size should be constrained.
    </p>
    <p class="margin-b20 padding-l30">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image34.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image34.png',
        ]"
      >
      </el-image> -->
      
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image34.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image34.png'),
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      When the screen is large, it is best to display pop-up layer near the user's operating position.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image35.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image35.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Use of side navigation
    </h5>
    <p class="p margin-b10 padding-l30">
      With the change of use posture of users, the usability of the bottom navigation bar on large-screen devices will decline, and the distribution of buttons will be more scattered, which may affect their identification and use.
    </p>
    <p class="p margin-b10 padding-l30">
      We encourage applications to use side navigation on larger screen to make full use of screen space and improve ease of use.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image36.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image36.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b10 nav-title" id="3-1-5">
      3.1.5 Multi-task and multi-window
    </h4>
    <p class="p margin-b10">
      On foldable screens and tablet devices, users prefer using larger screen space to run multiple tasks in parallel to improve efficiency and create productivity. The system provides the following three methods to allow applications to run in parallel in the form of multiple windows. Applications can be selected and accessed to meet users' multi-task requirements in various scenarios.
    </p>
    <p class="p margin-b10">
      <a class="a"  href="#4-3">
        Please refer to 4.3 Multi screen and multi window for relevant development guidance.
      </a>
    </p>
    <p class="margin-b20">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image37.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image37.png',
        ]"
      >
      </el-image> -->
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image37.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image37.png'),
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-1">
      3.1.5.1 Split screen
    </h5>
    <h5 class="h5 margin-b10 padding-l30">
      Split screen
    </h5>
    <p class="p margin-b10 padding-l30">
      Split screen is a common multi-task mode on mobile devices, by which the screen is divided into two or more areas and distributed to different applications or pages. In split-screen state, the size and position of the windows are relatively stable. It is suitable for long time parallel processing of two tasks.
    </p>
    <p class="p margin-b10 padding-l30">
      Since the form, ratio and size of split-screen windows of different devices are different, it is recommended to ignore the form and position of split screen after applications enter split screen and display corresponding layout according to actual width of the window in accordance with original breakpoint value.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image38.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image38.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-2">
      3.1.5.2 Floating window
    </h5>
    <p class="p margin-b10 padding-l30">
      Floating Window is a relatively temporary multi-task processing mode. The users can adjust the size and position of the floating window, or make the floating window adsorbed to and hide at the edge of the screen. Due to smaller size, the floating window is mainly used to present content or temporarily handle simple work in a multi-task scenario.
    </p>
    <p class="p margin-b10 padding-l30">
      Similarly, applications can also use floating window to open new pages and achieve parallel work within the same application.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image41.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image41.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-3">
      3.1.5.3 Parallel viewport/APPP multi-window/parallel window
    </h5>
    <p class="p margin-b10 padding-l30">
      Parallel window (vivo refers it as App multi window, Xiaomi refers it as parallel window, OPPO refers it as parallel viewport) is a capability provided by the system. Applications can open multiple instance pages in same screen through access framework to display more content.
    </p>
    <p class="p margin-b10 padding-l30">
      Parallel window is mainly used for takeout, e-commerce, life service and other types of applications. This kind of applications has a large number of pages. With parallel windows, basic experience optimization can be provided without interface adaptation. Parallel windows are generally automatically pulled up by application, or opened for entire application according to system settings, without the need of user's manual intervention.
    </p>
    <p class="p margin-b10 padding-l30">
      <a class="a"  href="#4-5">
        Please refer to 4.5 Activity Embedding for relevant development guidance
      </a>
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image42.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image42.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20 padding-l30">
      In parallel windows, the users' operation will still cause changes on both sides of the page, operation area is unstable, and user experience is poor. If the application can design a layout for a large screen, this method is not recommended for adaptation.
    </p>
    <h5 class="h5 margin-t20 margin-b20 nav-title" id="3-1-5-4">
      3.1.5.4 Cross-APP operation
    </h5>
    <p class="p margin-b10 padding-l30">
      In multi-window scenario, users are allowed to perform some intuitive cross-application operations, such as content drag and drop, to further reduce operating steps for cross-application sharing and give play to the advantages of large screen in operating efficiency.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image43.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image43.png',
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="3-2">
      3.2 Scenario adaptation cases
    </h3>
    <p class="p margin-b20">
      Starting from some common typical scenarios, we have sorted out some relatively general foldable-screen design cases. Developers can refer to these cases and implement layout adaptation on foldable screen in combination with application service types and experience scenarios.
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-1">
      3.2.1 Homepage scenarios
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      Comprehensive homepage
    </h5>
    <p class="p margin-b10 padding-l30">
      Comprehensive Homepage generally covers label bars, button groups, sliding elements and carousel charts.
    </p>
    <p class="p margin-b10 padding-l30">
      Generally, if an App has set the magnification and used responsive layout, such pages can be naturally adapted to medium and large screens. Some content needing to be scrolled will be displayed directly.
    </p>
    <p class="p margin-b10 padding-l30">
      For carousel charts that can be scrolled, direct proportional display will lead to occupation of a large amount of page space, affecting the viewing of bottom content. It is recommended to maintain the display height unchanged, and expand and display the previous and next pages on both sides.
    </p>
    <p class="p margin-b10 padding-l30">
      If there is a fixed Banner, it is recommended to separate background and content, and only enlarge background elements.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image44.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image44.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Card-stream homepage 
    </h5>
    <p class="p margin-b10 padding-l30">
      Card-Stream Homepage is commonly used in video/picture community and life service applications. When implementing adaptation for large-screen devices, the number of card columns should be increased to prevent excessive media content from affecting the aesthetics and information browsing efficiency.
    </p>
    <p class="p margin-b10 padding-l30">
      For pure pictures/video cards, columns can be increased according to the screen grid. Generally, the number of columns on medium screen is twice that of small screen, and the number of columns on large screen is three times that of small screen. However, for mixed cards with text content, due to misalignment of card heights, it is recommended to display fewer columns after increase of columns to avoid bring confusion to users and losing their reading position and progress.
    </p>
    <p class="p margin-b10 padding-l30">
      If it is inconvenient to implementing adaptation according to the grid, you may control the width of single-media content on medium and large screens to make it not exceed 30% of the screen and the height not exceed 50% of the screen, ensuring basic readability of the content.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image45.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image45.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Information-flow homepage
    </h5>
    <p class="p margin-b10 padding-l30">
      Information-flow Homepage is commonly used in social and information applications. Users usually need efficient stream browsing. It is recommended to indent both sides of the content to control the amount of text in a single line so as to improve reading efficiency.
    </p>
    <p class="p margin-b10 padding-l30">
      Enlarging media content in information stream will seriously affect the amount of information in a single page. Therefore, it is recommended to use magnification of screen to constrain display size of pictures and videos, or keep them with the same display size as straight-screen mobile phones to ensure that total amount of information in the screen does not decrease.
    </p>
    <p class="p margin-b10 padding-l30">
      Generally, it is not recommended to increase columns for information-flow page, because it will disrupt the users' reading and reduce their efficiency of obtaining information.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image46.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image46.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-2">
      3.2.2 Audio /visual entertainment scenarios
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      Video details
    </h5>
    <p class="p margin-b10 padding-l30">
      On a larger screen, frame structure of a video detail page can be adjusted to display information efficiently using width of the screen.
    </p>
    <p class="p margin-b10 padding-l30">
      If recommendation/comment tab is disassembled and expanded on the page, it will reduce content level and enhance content exposure effect.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image50.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image50.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Immerse video
    </h5>
    <p class="p margin-b10 padding-l30">
      Immersion Video Page is an interface structure which makes full use of screen width to allow users to view full-screen videos without entering full- screen mode.
    </p>
    <p class="p margin-b10 padding-l30">
      When designing with immersive video page, more attention should be paid to screen ratio of the device. It is recommended to set aside more than 30% of the screen height outside video area to put content, so as to avoid difficulties during user operation and browsing.
    </p>
    <p class="p margin-b10 padding-l30">
      *Since the length-width ratio of screen of tablet-device is similar to that of mainstream videos, immersive video design may prevent other content on the page from being displayed. We recommend that applications should still use normal column layout in this case.
    </p>

    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image51.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image51.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Hover video
    </h5>
    <p class="p margin-b10 padding-l30">
      Half-Fold Hover is a special state of foldable screen models. After entering hover mode, users can place their mobile phones on desktop and watch videos without brackets, or place mobile phones on the ground and follow the video for indoor fitness activities.
    </p>
    <p class="p margin-b10 padding-l30">
      In hover state, the application should be designed according to the logic of "upper part for content display, lower part for operation expansion ". Video and display information are played on the upper part of the screen, and operation keys are placed on the lower part to ensure that users can control the content conveniently and centrally, and mobile phones are stable during operation. 
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image52.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image52.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Quick view of short video 
    </h5>
    <p class="p margin-b10 padding-l30">
      For short videos, vertical ratio is usually adopted; the screen space utilization rate on foldable screen is low.
    </p>
    <p class="p margin-b10 padding-l30">
      Users are allowed to scroll out author's homepage or playlist to quickly view and switch videos while playing.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image53.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image53.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Live/ comment separation
    </h5>
    <p class="p margin-b10 padding-l30">
      Similarly, on some vertical ratio live broadcast pages, video and comments can also be separated on large screen. It not only ensures complete display of video content, but also can display more interactive content, or expose operational content.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image54.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image54.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Music play
    </h5>
    <p class="p margin-b10 padding-l30">
      The application can expand lyrics and other content on music play page, making full use of screen space.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image55.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image55.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      View content while playing
    </h5>
    <p class="p margin-b10 padding-l30">
      On a larger screen, users are allowed to temporarily use part of the screen space to view comments, album information and other content during music play.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image57.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image57.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-3">
      3.2.3 Communication scenarios
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      IM and mail list page
    </h5>
    <p class="p margin-b10 padding-l30">
      Instant Messaging and email applications should use a parent-child structure to promote content at next level to the child level, which can reduce application levels and improve browsing efficiency.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image58.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image58.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Video call
    </h5>
    <p class="p margin-b10 padding-l30">
      During a video call, the screen display should be as complete as possible. Do not fill screen directly, which may cause screen to be truncated.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image59.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image59.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      In addition, on a larger screen, the application can also apply Equal method to display videos of both sides of the call.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image60.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image60.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Hover call
    </h5>
    <p class="p margin-b10 padding-l30">
      In the scenario of video call or multi-person meeting, the users can also place mobile phone on desktop to make h over call.
    </p>
    <p class="p margin-b10 padding-l30">
      In hover state, the application should be designed according to the logic of "upper part for content display, lower part for operation expansion ". Video and display information are played on the upper part of the screen, and operation keys are placed on the lower part to ensure that users can control the content conveniently, and mobile phones are stable during operation.
    </p>
    <p class="margin-b30 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image61.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image61.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-4">
      3.2.4 Forum/community scenarios
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      Picture and text detail page
    </h5>
    <p class="p margin-b10 padding-l30">
      For the picture and text detail page of the community content, the pictures should not be directly enlarged, because directly enlargement affects the aesthetics and browsing efficiency.
    </p>
    <p class="p margin-b10 padding-l30">
      It is recommended to adjust page frame, use column layout, and place comments or recommendations on the right side of the screen.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image65.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image65.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Forum content detail Page
    </h5>
    <p class="p margin-b10 padding-l30">
      For the content detail page, the use mode of users is similar to streaming reading. It is recommended to indent both sides of the content to facilitate users to read text content efficiently. For displaying on tablet, the indented space can be used to display relevant recommended content.
    </p>
    <p class="p margin-b10 padding-l30">
      Similarly, for pictures and video elements, magnification should be used for adaptation, or display size similar to that of a straight-screen phone should be still used.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image66.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image66.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4  margin-t20 margin-b20 nav-title" id="3-2-5">
      3.2.5 Information scenarios
    </h4>
    <h5 class="h5 margin-b10 padding-l30">
      Information list page
    </h5>
    <p class="p margin-b10 padding-l30">
      There is a lot of streaming content in information list page. It is recommended to indent both sides of the page for convenience of users for reading.
    </p>
    <p class="p margin-b10 padding-l30">
      Similarly, for pictures and video elements, magnification should be used for adaptation, or display size similar to that of a straight-screen phone should be still used. For combination of single line and multiple pictures, you can keep frame scaling, but it is recommended that the width of a single picture should not exceed 30% of the overall width of the content area.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image67.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image67.png',
        ]"
      >
      </el-image>
    </p>
    <h5 class="h5 margin-b10 padding-l30">
      Information content page
    </h5>
    <p class="p margin-b10 padding-l30">
      The information content page is generally a mixture of pictures and text content, with high reading intensity. It is generally necessary to indent both sides of the content so as to improve users’ reading comfort.
    </p>
    <p class="p margin-b10 padding-l30">
      The screen of tablet device is large. If there are still too many words in a single line after indentation, the font size of the content should be appropriately scaled up.
    </p>
    <p class="p margin-b10 padding-l30">
      Pictures and video elements can be scaled down to the same width as text content. However, due to special screen ratio of tablet devices, it is recommended to limit the maximum height of media content to avoid affecting the browsing and acquisition of content.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image68.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image68.png',
        ]"
      >
      </el-image>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="3-2-6">
      3.2.6 Game scenarios
    </h4>
    <p class="p margin-b10 padding-l30">
      For game scenarios, it is recommended that developers optimize layout of large screen according to different game types and scenarios, so as to bring users pleasant experience at presentation and interaction level. At the same time, considering that users hold devices for a long time while they play the game, adaptation should be optimized according to different interaction areas when implementing layout for flat state.
    </p>
    <p class="p margin-b10 padding-l30">
      Considering diverse game types, only the strategies that are not recommended and need to be avoided during adaptation are described below.
    </p>
    <p class="p margin-b10 padding-l30">
      Strategy 1 (not recommended): The game interface is displayed in the center according to original ratio in large screen while other parts are left blank/black. The advantage of large screen is not fully utilized.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image69.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image69.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      Strategy 2 (not recommended): The operation control and game screen are scaled up proportionally in large screen, which results in a smaller user view and incomplete information.
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image70.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image70.png',
        ]"
      >
      </el-image>
    </p>
    
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="4">
      IV. Development guidance
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-1">
      4.1  Screen compatibility
    </h3>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-1-1">
      4.1.1 App resizable capability support
    </h4>
    <p class="p margin-b10 padding-l30">
      Android Android devices vary in shape and size, so the layout of Apps needs to be flexible. In other words, the layout should be able to deal with different screen sizes and orientations, instead of defining rigid sizes for the layout to assume that screen size and width are fixed.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Therefore, the essence of multi modality screen switching adaptation is that during screen operation, when the screen's size, density or ratio changes,   the App should be able to continue to display and operate normally on the changed screen.</b>
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image78.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image78.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      In this section, these topics and functions available on Android are briefly described to help you adjust your screen accordingly so as to support different screen sizes.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>To ensure that your application interface can be displayed in full screen on different sizes of screen, t is strongly recommended that the application configures resizableActivity as “true” to support full screen display.</b>
    </p>
    <p class="p margin-b10 padding-l30">
      To adapt to multi modality screen switching, first of all, we need to enable the application to support dynamic size change. For that, we need to declare in the Application or corresponding Activity in the manifest
    </p>
    <div class="code margin-b10 margin-l30">
      <p class="p ">android:resizeableActivity="true"   </p>  
    </div>

    <p class="p margin-b10 padding-l30">
      You need to make statement that the App can support “resizable” capability according to the API Level (targetSdkVersion) to which the App is oriented.
    </p>
    <p class="p margin-b10 padding-l30">
      ● If the App is oriented to API Level 24 + (targetSdkVersion>=24), the App will be deemed by the system as being able to support “resizable” capability by default.
    </p>
    <p class="p margin-b10 padding-l30">
      If the App is oriented to API Level 24 - (targetSdkVersion&lt;24), it is needed to make App explicitly declares in the manifest that android:resizeableActivity=true so as to support resizable capability.
    </p>
    <p class="p margin-b10">
      Note: Although Android provides the capability to apply for restricted screens, it is strongly recommended that you design the Resizable Capability for applications, because once you declare the ratio of restricted screens (maximum or minimum), it means that when your application runs on a screen with a ratio that exceeds the range you declare, your application will appear black edges on screen and other phenomena. It will seriously affect user experience of the application. See the developers adaptation guide for restricted screen support in the 
      <a
        class="a"
        href="https://developer.android.google.cn/guide/practices/screens-distribution.html"
      >
        <i class="el-icon-paperclip"></i>
        Instructions for declaring restricted screen
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-1-2">
      4.1.2 Application layout optimization
    </h4>
    <p class="p margin-b10 padding-l30">
      In the process of adapting screens with different size, it is recommended that you optimize layout so as to ensure the best layout display effect under multi modality screens, such as displaying more and clearer content.
    </p>
    <p class="p margin-b10 padding-l30">
      The layout and display with correct and beautiful application interface include:
    </p>
    <p class="p margin-b10 padding-l30">
      ● Ensure that your layout can properly implement adjustment according to the screen;
    </p>
    <p class="p margin-b10 padding-l30">
      ● Provide appropriate UI layout according to screen configuration;
    </p>
    <p class="p margin-b10 padding-l30">
      ● Ensure right layout for right screen;
    </p>
    <p class="p margin-b10 padding-l30">
      ● Provide a bitmap that can be scaled normally.
    </p>
    <p class="p margin-b10 padding-l30">
      Please refer to Chapter 3<a
        class="a"
        href="#3"
      >
        《Design Guidelines for details》
      </a>
      ，or refer to the Android Developers' Guide for
      <a
        class="a"
        href="https://developer.android.google.cn/training/multiscreen/screensizes?"
      >
        <i class="el-icon-paperclip"></i>
        supporting different screen sizes
      </a>
      and
      <a
        class="a"
        href="https://developer.android.com/guide/topics/large-screens/migrate-to-responsive-layouts"
      >
        <i class="el-icon-paperclip"></i>
        migrating interface to adaptive layout.
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-2">
      4.2 Application continuity
    </h3>
    <p class="p margin-b10 padding-l30">
      To ensure that your App can switch seamlessly during screen switching, application design continuity is needed to ensure that your application task will not be interrupted. The best experience is that in the process of expansion and switching, no App restart occurs and the tasks and relevant states before the switching can be retained and continued.
    </p>
    <p class="p margin-b10 padding-l30">
      Three-party applications support continuity and the attribute “resizableActivity=true “ needs to be added in file: AndroidManifest.xml 
or actvivity tag:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">&lt;application android:resizeableActivity="true">    </p>  
      <p class="p padding-l30">&lt;activity android:resizeableActivity="true" />  </p>
      <p class="p ">&lt;/application> </p>
    </div>
    <p class="p margin-b10 padding-l30">
      If the targetSDK of the application is 24 or above, it is “true” by default even if the attribute of “resizeableActivity” is not set. After screen switching, the application shall be able to properly save the interface state or support configuration change.
    </p>
    <p class="p margin-b10 padding-l30">
      The action of multimodal screen switching (folded screen or projection screen) will trigger configuration changes to smallestscreensize, screensize and screenlayout, as well as direction and density. Every time the configuration changes, the entire activity will be destroyed and recreated by default. It is recommended that you listen configchanges message of the listener system through registration so as to process the configuration changes without restarting the application. You need to add “android:configchanges” attribute in the manifest, in which the following values should at least be included:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">android:configChanges="screenSize|smallestScreenSize|screenLayout|orientation|density"</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      Among them, orientation is particularly important on some devices that landscape screen holding is default mode when some foldable screen is expanded, because the manufacturer forces some Apps to use landscape screen display; Density is widely used in projection scenarios; projection scenarios cannot ensure that densities of different screens are same. You need to copy the “onConfigurationChanged() “ method to obtain the screen resolution, density and other information through the configuration parameters of this method, and then you can make corresponding adjustments to the interface layout of different screens, such as switching layout, adjusting control element position and spacing.
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">@Override</p>
      <p class="p ">public void onConfigurationChanged(Configuration newConfig) {</p>  
      <p class="p padding-l30">super.onConfigurationChanged(newConfig);</p> 
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      If your application hasto go through a new life cycle to respond to screen switching, save and restoration of the state is required.
    </p>
    <p class="p margin-b10 padding-l30">
      You can save previous state and restore it later through onSaveInstanceState() and ViewModel objects. That is, before destroying the activity, store the state through onSaveInstancesState(), and restore the state in onCreate() or onRestoreInstanceState().
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Note：</b>The application should not terminate the process by calling finish() in OnDestroy() or through other approaches. This will lead to problems such as the App closing and flashing back when the device's multi-modal screen switches.
    </p>
    <p class="p margin-b10 padding-l30">
      The application should not hook the config in the resource context. Instead, the config and Resources objects should be created uniformly by the system.
    </p>
    <p class="p margin-b10 padding-l30">
      For details, please refer to the Android Developers' Guide for  
      <a
        class="a"
        href="https://developer.android.com/guide/topics/resources/runtime-changes"
      >
        <i class="el-icon-paperclip"></i>
        handling configuration changes 
      </a>
      and
      <a
        class="a"
        href="https://developer.android.com/topic/libraries/architecture/saving-states?hl=zh-cn"
      >
        <i class="el-icon-paperclip"></i>
        saving interface state.
      </a>
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-2-1">
      4.2.1  Proper utilization of application resources
    </h4>
    <p class="p margin-b10 padding-l30">
      1、Whether for a multi-window scenario or a full-screen scenario, it is strongly recommended that App layout view should be based on window size (because the window size of a multi-window scene is not equivalent to the screen size). The layout should not be based on the screen size. For example, if in window mode, the layout is still based on the width and height of the screen, it will cause layout problems such as App icon truncation and layout confusion.
    </p>
    <p class="p margin-b10 padding-l30">
      2、It is recommended to use the Activity context to obtain window size instead of obtaining screen size, because the screen size of multi window scenarios such as Activity Embedding, split screen and floating window is not equal to the window size.
    </p>
    <p class="p margin-b10 padding-l30">
      Android R Start:
    </p>
    <p class="p margin-b10 padding-l30">
      Obtain the window size of current activity:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">android.view.WindowMetrics windowMetrics = activity.getWindowManager().getCurrentWindowMetrics();</p>
      <p class="p ">Rect windowRect = windowMetrics.getBounds();</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      Obtain the screen size of current activity:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">android.view.WindowMetrics maxWindowMetrics = this.getWindowManager().getMaximumWindowMetrics();</p>
      <p class="p ">Rect maxWindowRect = maxWindowMetrics.getBounds();</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      Previous version of Android R:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">androidx.window.layout.WindowMetricsCalculator wtc = androidx.window.layout.WindowMetricsCalculator.getOrCreate();</p>
      <p class="p ">androidx.window.layout.WindowMetrics windowMetrics = wtc.computeCurrentWindowMetrics(activity);</p>  
      <p class="p ">Rect windowRect = windowMetrics.getBounds();</p>
      <p class="p ">androidx.window.layout.WindowMetrics maxWindowMetrics = wtc.computeMaximumWindowMetrics(activity);</p>  
      <p class="p ">Rect maxWindowRect = maxWindowMetrics.getBounds();</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      For previous versions of Android R, the dependencies of required artifacts need to be added in build.gradle file of the application or module:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">dependencies {</p>
      <p class="p padding-l30">implementation "androidx.window:window:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For Java-friendly APIs to register and unregister callbacks</p>  
      <p class="p padding-l30">implementation "androidx.window:window-java:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For RxJava2 integration</p>  
      <p class="p padding-l30">implementation "androidx.window:window-rxjava2:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For RxJava3 integration</p>  
      <p class="p padding-l30">implementation "androidx.window:window-rxjava3:1.1.0-alpha02"</p>  
      <p class="p padding-l30">// For testing</p>
      <p class="p padding-l30">implementation "androidx.window:window-testing:1.1.0-alpha02"</p>
      <p class="p ">}</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      For details, please refer to 
      <a
        class="a"
        href="https://developer.android.com/jetpack/androidx/releases/window"
      >
        <i class="el-icon-paperclip"></i>
        WindowManager JetPack
      </a> 
      in the Android Developers' Guide.
    </p>
    <p class="p margin-b10 padding-l30">
      3、It is recommended to use the Activity context to obtain the resource configuration.
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">Configuration config = activity.getResources().getConfiguration();</p>
    </div>
    <p class="p margin-b10 padding-l30">
      The application should not hook the interface of resource activity.getResources(), that is, the application should not construct its own Configuration and Resources to actively control the distribution and refresh of Configuration.
    </p>
    <p class="p margin-b10 padding-l30">
      4、Obtain the position of control View correctly
    </p>
    <p class="p margin-b10 padding-l30">
      Calling the getLocationOnScreen and getLocationInWindow interfaces of View has different meanings in multi-window scenarios. The former is absolute position of the control on the screen (relative to the starting point of the upper left corner of the screen) while the latter is relative position of the control inside the window (relative to the starting point of the upper left corner of the window).
    </p>
    <p class="p margin-b10 padding-l30">
      5、Use appropriate bitmap
    </p>
    <p class="p margin-b20 padding-l30">
      On a large-screen device, if a picture is magnified, it may not be displayed clearly. For that reason, it is recommended to provide a higher density level of picture and save it in the mipmap directory, or change it into vector graphics. Use pictures and vector graphics for scenarios in which some display areas cannot be enlarged.
    </p>
    <h4 class="h4 margin-t20 margin-b20 nav-title" id="4-2-2">
      4.2.2 Dynamic support for landscape screen and portrait screen switching
    </h4>
    <p class="p margin-b10 padding-l30">
      The application only supports portrait screen (or landscape screen) and landscape/portrait screen switching according to device resolution height-width ratio dynamic settings. The best experience of the application is to support single direction in small-screen phone mode, such as portrait or landscape screen. Devices with large screens whose height and width are close support rotary screens (except for games, camera and other special types of applications). If the application design is consistent with this purpose, the following instance code can be used to dynamically change the direction.
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public class MainActivity extends AppCompatActivity {</p>
        <p class="p padding-l30">@Override</p>  
        <p class="p padding-l30">protected void onCreate(Bundle savedInstanceState) {</p>  
          <p class="p padding-l60">super.onCreate(savedInstanceState);</p>  
          <p class="p padding-l60">setRequestedOrientation(this, this.getResources().getConfiguration());</p>  
          <p class="p padding-l60">setContentView(R.layout.activity_main);</p>  
        <p class="p padding-l30">}</p> 

        <p class="p padding-l30">@Override</p>  
        <p class="p padding-l30">public void onConfigurationChanged(@NonNull Configuration newConfig) {</p>    
          <p class="p padding-l60">super.onConfigurationChanged(newConfig);</p>  
          <p class="p padding-l60">setRequestedOrientation(this, newConfig);</p>  
          <p class="p padding-l60">// update layout and redraw</p>  
        <p class="p padding-l30">}</p> 

        <p class="p padding-l30">public static void setRequestedOrientation(Activity activity, Configuration config) {</p>  
          <p class="p padding-l60">float longSide = Math.max(config.screenWidthDp, config.screenHeightDp);</p>  
          <p class="p padding-l60">float shortSide = Math.min(config.screenWidthDp, config.screenHeightDp);</p>  
          <p class="p padding-l60">boolean isHeighRatio = longSide / shortSide > 16f / 9;</p>  
          <p class="p padding-l60"> if (isHeighRatio) {</p>  
            <p class="p padding-l90">// phone, foldable device in folded mode, pocket phone in expanded mode</p>  
            <p class="p padding-l90">activity.setRequestedOrientation(ActivityInfo.SCREEN_ORIENTATION_PORTRAIT);</p>  
          <p class="p padding-l60">} else {</p>  
            <p class="p padding-l90">// tablet, foldable device in expanded mode, pocket phone in folded mode, tv</p>  
            <p class="p padding-l90">activity.setRequestedOrientation(ActivityInfo.SCREEN_ORIENTATION_UNSPECIFIED);</p>  
          <p class="p padding-l60">}</p>  

        <p class="p padding-l30">}</p> 
      <p class="p ">}</p>  
    </div>
    <p class="p margin-b10 padding-l30">
      For details, please refer to the official document of Google: 
      <a
        class="a"
        href="https://developer.android.com/guide/topics/ui/foldables?hl=zh-cn#应用连续性"
      >
        <i class="el-icon-paperclip"></i>
        Application Continuity.
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-3">
      4.3 Multi screen and multi window
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-1">
      4.3.1 Support split screen and floating window mode
    </h4>
    <p class="p margin-b10 padding-l30">
      It is required to hold resizable to allow the system to trigger to start the application in split screen or suspended window mode according to the user scenario.
    </p>
    <p class="p margin-b10 padding-l30">
      For details, please refer to the 
      <a
        class="a"
        href="https://developer.android.com/guide/topics/ui/multi-window"
      >
        <i class="el-icon-paperclip"></i>
        multi window support
      </a>
      in the Android Developers' Guide.
     
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-2">
      4.3.2 Multi-resume
    </h4>
    <p class="p margin-b10 padding-l30">
      When running on device with Android 9.0 and lower versions, only the App having got focus is Resumed. Any other visible Activities are Paused. If App closes resources or stops playing content when it is Paused, problems may come out.
    </p>
    <p class="p margin-b10 padding-l30">
      Since Android 10, this case has changed. That is, when a device is in multi-window mode, all activities will remain in resumed state. This is called Multi-Resume. Please note that if there is a transparent activity on the top, or the activity cannot become the focus (for example, in the Picture-in-Picture mode), corresponding activity may be in the Paused state. Another possibility is that all Activities do not have focus at a specific time (for example, when opening a drawer notification bar). OnStop will continue to work as usual. Whenever an Activity is removed from the screen, the system calls it.
    </p>
    <p class="p margin-b10 padding-l30">
      Some devices with Android 9.0 also provide multi-resume function. To enable multi-resume function on these devices, you need to add the following meta- data:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">
        &lt;meta-data android:name="android.allow_multiple_resumed_activities" android:value="true" />
      </p>
    </div>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-3">
      4.3.3 Access to exclusive resources
    </h4>
    <p class="p margin-b10 padding-l30">
      To help support multi-resume function, Android provides a new lifecycle callback  Activity # onTopResumedActivityChanged().
    </p>
    <p class="p margin-b10 padding-l30">
      The system will call this method when an Activity gets or loses the top resume Activity position (that is, when an Activity in multi-resumed state gets focus or loses focus). It is important to understand this when an Activity usees shared single-user resources (such as Macs or cameras).
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">
        protected void onTopResumedActivityChanged(boolean topResumed) {
      </p>
        <p class="p padding-l30">
          if (topResumed) {
        </p>
        <p class="p padding-l30">
          } else {
        </p>
        <p class="p padding-l30">
          }
        </p>
      <p class="p ">
        }
      </p>
    </div>
    <p class="p margin-b10 padding-l30">
      Please note that applications may lose resources for various other reasons, such as removing of shared hardware.
    </p>
    <p class="p margin-b10 padding-l30">
      In any case, applications should be able to properly handle resource loss events and state changes that affect available resources.
    </p>
    <p class="p margin-b10 padding-l30">
      For applications using cameras, it is recommended to use
    </p>
    <p class="p margin-b10 padding-l30">
      CameraManager.AvailabilityCallback#onCameraAccessPrioritiesChanged()
method as prompt to remind that it may be a good time to try to access the camera. This method can be used in Android 10 (API level 29) or later version 4.3.3.
    </p>
    <p class="p margin-b10 padding-l30">
      When receiving Activity#onTopResumedActivityChanged(topResumed) callback,
    </p>
    <p class="p margin-b10 padding-l30">
      ● when topResumed=false, it is necessary to judge whether to release exclusive resources at this time. It is not necessary to release resources as soon as the focus is lost;
    </p>
    <p class="p margin-b10 padding-l30">
      ● When topResumed=true, you can apply for exclusive camera resources. Applications that originally hold camera resources will throw CameraAccessException to subsequent calls to camera devices  after   receiving CameraDevice.StateCallback#onDisconnected()  callback.
    </p>
    <p class="p margin-b20 padding-l30">
      Please note that resizeableActivity=false does not guarantee exclusive access to camera, because other applications using the camera may open it on other displays.
    </p>
    <p class="margin-b10 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image79.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image79.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p w750 margin-b20  padding-l60 text-center">
      Camera in multi-window mode
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-4">
      4.3.4 Requested orientation in multi-window mode
    </h4>
    <p class="p margin-b10 padding-l30">
      In multi-window mode, the method of locking orientation is ineffective. That is to say, in a multi-window scenario, applications that only support portrait screens may also have landscape-screen scenarios such as the case that the width of window is larger than length. Therefore, for interface layout, two types of scenarios, including the cases that the width of window is larger than length and the length of window is larger than width must be considered.
    </p>
    <p class="p margin-b10 padding-l30">
      1）setRequestedOrientation()
    </p>
    <p class="p margin-b10 padding-l30">
      2）android:screenOrientation
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-3-5">
      4.3.5 Multi-display scenarios
    </h4>
    <p class="p margin-b10 padding-l30">
      Android 10 (API level 29) or later version supports Activities on secondary display. If an Activity is running on a device with multiple displays, users can move the Activity from one display to another. Multi-resume function is also suitable for multi-display scenarios. Multiple Activities can receive user input at the same time.
    </p>
    <p class="p margin-b10 padding-l30">
      App can specify on which display it will run when starting or creating other Activities. This depends on the Activity startup mode defined in the manifest and Intent tags and options (set by the entity that starts the Activity).
    </p>
    <p class="p margin-b10 padding-l30">
      The same a multi modality screen switching, when an Activity is moved to secondary display, the system will update the context, resize the window, and change configuration and resources. If the Activity handles configuration changes, it will receive notification in onConfigurationChanged(). If not, it will restart.
    </p>
    <p class="p margin-b10 padding-l30">
      If the configuration change has been processed, the Activity should check current display in onCreate and onConfigurationChanged to ensure that resources and layouts are updated when display changes.
    </p>
    <p class="p margin-b10 padding-l30">
      If the startup mode selected for an Activity supports multiple instances, please remember that a new activity instance will be created when starting on secondary screen. The two activities will be resumed at the same time.
    </p>
    <p class="margin-b10">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image80.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image80.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      An activity has multiple instances in multiple displays.
    </p>
    <p class="p margin-b10 padding-l30">
      For more information about multiple displays, please refer to <a
        class="a"
        href="https://developer.android.com/about/versions/oreo/android-8.0#mds"
      >
        <i class="el-icon-paperclip"></i>
        Multiple displays
      </a>
      in the Android Developers' Guide.
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-4">
      4.4 Foldable screen adaptation
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-4-1">
      4.4.1 Classification of foldable screens 
    </h4>
    <p class="p margin-b10 padding-l30">
      At present, the portrait foldable screen is only different from the ordinary straight screen mobile phone in that the screen can be folded。 It will not be discussed here.
    </p>
    <p class="p margin-b10 padding-l30">
      In landscape foldable screens, there are two forms: the foldable-screen device expanded by default in landscape-screen mode and the foldable screen device that is expanded by default in portrait-screen mode.
    </p>
    <h4 class="h4 margin-t30 nav-title" id="4-4-2">
      4.4.2  Differences between two categories of foldable screens
    </h4>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image81.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image81.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      For foldable-screen device with portrait screen after expansion by default, the rotation of the expanded large portrait screen is 0 degree, the screen orientation is portrait, and the screen width is less than the height, which is consistent with the cognition of ordinary mobile phones.
    </p>
    <p class="p margin-b10 padding-l30">
      For foldable-screen device with landscape screen after expansion by default, the rotation of the expanded large landscape screen is 0 degree,
the screen orientation is landscape, and the screen width is greater than the height.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-4-3">
      4.4.3 Suggestions on foldable screen adaptation
    </h4>
    <p class="p margin-b10 padding-l30">
      1、It is recommended that the application only supports portrait screen (or landscape screen) and portrait/landscape screen switching according to device resolution height-width ratio dynamic settings.
    </p>
    <p class="p margin-b10 padding-l30">
      2、Respond to onConfigurationChanged to ensure service continuity.
    </p>
    <p class="p margin-b10 padding-l30">
      3、Do not use rotation when designing layout for App, but deploy layout processing according to the value of width and height to avoid repeated adaptation of two types of foldable screens.
    </p>
    <p class="p margin-b10 padding-l30">
      4、For App layout, the landscape screen and portrait screen orientation should be considered at the same time. For live broadcast and short video pages implemented by SurfaceView, and the pages implemented by H5 loaded by Webview, such as operation page and applet/game, particularly, both width and height dimensions should be considered at the same time during App layout , so as to cope with second type of foldable screen expansion scenario.
    </p>
    <p class="p margin-b10 padding-l30">
      5、For some video maximizing landscape screen playback scenarios, judge logic of maximizing clicks. It is not recommended to associate with landscape screen and portrait screen state, but to maintain a maximized state.
    </p>
    <p class="p margin-b10 padding-l30">
      6、When using camera, pay attention to landscape screen and portrait screen scenarios and front and rear cameras, refresh preview orientation and picture size in the life cycle in time to ensure that device rotation can display preview and take pictures normally.
    </p>
    <p class="p margin-b10 padding-l30">
      7、For windows whose aspect ratio is less than 16:9, avoid scaling the density or overwriting measure according to the screen width. H5 does not set the scale of the viewport. Instead, it fills the screen by increasing the number of horizontal lists or increasing the spacing.
    </p>
    <p class="p margin-b10 padding-l30">
      8、Consider the case that the width of expanded screen of foldable screen is larger than the height, and the pictures of game cover the entire screen. Avoid the phenomenon of screen blur caused by not covering the whole screen.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-4-4">
      4.4.4 Foldable screen state listen and acquisition
    </h4>
    <p class="p margin-b10 padding-l30">
      1、Folding and expansion of foldable screen only means that the size of the display changes, and the App is notified to update layout through onConfigurationChanged. The App obtains its own WindowMetrics in onConfigurationChanged to dynamically adapt the layout. The App should not care about the state of folding and expansion.
    </p>
    <p class="p margin-b10 padding-l30">
      2、Vivo (starting from Android 12L), OPPO and Xiaomi (starting from Android T) support sensing the flat, half-opened and half-opened area through Google's JetPack. It is used for App to listen half-opened state for hover layout. Currently, the flat and half-opened states are FLAT and HALF_OPENED respectively. The difference between the flat and half-opened states is provided, because these two states are in the same display size, and no onConfigurationChanged is reported to the application, so Listen function has to be added to enable the application to identify the flat state and the half-opened state, and implement layout updates in the switching callback of expanded state and half-opened state to achieve half folded hover effect.
    </p>
    <p class="p margin-b10 padding-l30">
      There are two kinds of half-opened states for foldable screen:
    </p>
    <p class="p padding-l30">
      1）Up-down Folding: the lower half of screen is placed on the desktop for use, that is, TableTop mode.
    </p>
    <p class="p padding-l30">
      2）Left-right Folding: use the screen like turning a book, namely, Book mode.
    </p>
    <p class="margin-b20">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w600"
        :src="configBaseIMG+'/whiteBookFile/image82.jpg'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image82.jpg',
        ]"
      >
      </el-image> -->
      
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image82.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image82.png'),
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Note: If the application supports switching between the above-mentioned two states, the states will be notified again during switching. That is, if the application page supports rotating screen, the folding direction will change when the screen is rotated, and the system will notify the listener again. The application can obtain the folding direction again when receiving the Listen message for layout updating.</b>
    </p>
    <p class="p margin-b10 padding-l30">
      3、Jetpack WindowManager is constructed to support different outlook specifications of foldable device, and is designed to support devices to be released in the future. To support compatibility in the future, the system will provide display information as a part of WindowLayoutInfo in the list of DisplayFeature (the actual type of foldable screen is FoldingFeature, inherited from DisplayFeature) elements. This basic interface describes the physical functions of display.
    </p>
    <p class="p margin-b10 padding-l30">
      The key APIs of FoldingFeature are as follows:
    </p>
    <p class="p margin-b20 padding-l60">
      ● getState(): get the flat state or half-opened state. The values are FoldingFeature.State.FLAT and FoldingFeature.State HALF_ OPENED. The folded state is special. At present, there is no value for folded state, and the folded state will directly go onConfigurationChanged (the switching of the folded state and the flat state involves the switching of the display size. It will trigger the application to go onConfigurationChanged; for the half-opened state and the expanded state, it will not trigger the application to go onConfigurationChanged, because the display size has not changed). If the application has to get the folded state, In the callback accept of LayoutStateChangeCallback, if the size of getDisplayFeatures () is “0 “ according to WindowLayoutInfo, it indicates that there is no expanded state or half-opened state, which means that the expanded or half-opened state has been switched to folded state.
    </p>
    <p class="p margin-b10 padding-l30">
      In the half-opened state, if the application page supports landscape screen and portrait screens, the LayoutStateChangeCallback will be called back again, and the developers can obtain the new crease position and folding orientation, so as to refresh the view in half-opened state.
    </p>
    <p class="p margin-b10 padding-l60">
      ● getBounds(): It can get the crease position of foldable screen. When folding up and down, the returned area width is greater than the height, such as [0, 960, 1792, 960]; When folding left and right, the returned area width is less than the height, such as [960, 0, 960, 1792].
    </p>
    <p class="p margin-b20 padding-l60">
      ● getOrientation(): Foldable screen folding direction, FoldingFeature.Orientation.HORIZONTAL indicates folding up and down, FoldingFeature.Orientation.VERTICAL indicates folding left and right.
    </p>
    <p class="p margin-b10 padding-l30">
      During development, the dependencies of the required artifacts need to be added in the build.gradle file of the application or module:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">dependencies {</p>
        <p class="p padding-l30">
          implementation "androidx.window:window:1.1.0-alpha02"
        </p>
        <p class="p padding-l30">
          // For Java-friendly APIs to register and unregister callbacks
        </p>
        <p class="p padding-l30">
          implementation "androidx.window:window-java:1.1.0-alpha02"
        </p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      Specific instance code:
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">private WindowInfoTrackerCallbackAdapter windowInfoTracker;</p>
      <p class="p ">private final LayoutStateChangeCallback layoutStateChangeCallback = new LayoutStateChangeCallback();</p>
      <p class="p ">@Override</p>
      <p class="p ">protected void onCreate(@Nullable Bundle savedInstanceState) {</p>
        <p class="p padding-l30">super.onCreate(savedInstanceState);</p>
        <p class="p padding-l30">windowInfoTracker = new WindowInfoTrackerCallbackAdapter(WindowInfoTracker.getOrCreate(this));</p>
      <p class="p ">}</p>
      <p class="p ">@Override</p>
      <p class="p ">protected void onStart() {</p>
        <p class="p padding-l30">super.onStart(); {</p>
        <p class="p padding-l30"> windowInfoTracker.addWindowLayoutInfoListener(this, Runnable::run, layoutStateChangeCallback);</p>
      <p class="p ">}</p>
      <p class="p ">@Override</p>
      <p class="p ">protected void onStop() {</p>
        <p class="p padding-l30">super.onStop();</p>
        <p class="p padding-l30">windowInfoTracker.removeWindowLayoutInfoListener(layoutStateChangeCallback);</p>
      <p class="p ">}</p>
      <p class="p ">class LayoutStateChangeCallback implements Consumer&lt;WindowLayoutInfo> {</p>
        <p class="p padding-l30">@Override</p>
        <p class="p padding-l30">public void accept(WindowLayoutInfo newLayoutInfo) {</p>
          <p class="p padding-l60">// Use newLayoutInfo to update the Layout</p>
          <p class="p padding-l60">List&lt;DisplayFeature> displayFeatures = newLayoutInfo.getDisplayFeatures();</p>
          <p class="p padding-l60">if (displayFeatures.size() == 0) {</p>
            <p class="p padding-l90">// enter folded state, you can update the layout here or in onConfigurationChanged()</p>
          <p class="p padding-l60">}</p>

        <p class="p padding-l60">for (DisplayFeature feature : displayFeatures) {</p>
          <p class="p padding-l90">if (feature instanceof FoldingFeature) { </p>
            <p class="p padding-l120">if (isTableTopPosture((FoldingFeature) feature)) {</p>
              <p class="p padding-l150">enterTabletopMode(feature);</p>
            <p class="p padding-l120">} else if (isBookPosture((FoldingFeature) feature)) {</p>
              <p class="p padding-l150">enterBookMode(feature);</p>
            <p class="p padding-l120">} else if (isSeparating((FoldingFeature) feature)) {</p>
              <p class="p padding-l150">// Dual-screen device</p>
              <p class="p padding-l150">if (((FoldingFeature) feature).getOrientation() ==</p>
                  <p class="p padding-l180">FoldingFeature.Orientation.HORIZONTAL) {</p>
                  <p class="p padding-l180">enterTabletopMode(feature);</p>
              <p class="p padding-l150">} else {</p>
                  <p class="p padding-l180">enterBookMode(feature);</p>
              <p class="p padding-l150">}</p>
            <p class="p padding-l120">} else {</p>
              <p class="p padding-l150">enterNormalMode();</p>
            <p class="p padding-l120">}</p>
          <p class="p padding-l90">}</p>
        <p class="p padding-l60">}</p>
        <p class="p padding-l30">}</p>
      <p class="p ">}</p>

      <p class="p">private boolean isTableTopPosture(FoldingFeature foldFeature) {</p>
        <p class="p padding-l30">return (foldFeature != null) && (foldFeature.getState() == FoldingFeature.State.HALF_OPENED) && (foldFeature.getOrientation() == FoldingFeature.Orientation.HORIZONTAL);</p>
      <p class="p">}</p>

      <p class="p">private boolean isBookPosture(FoldingFeature foldFeature) {</p>
        <p class="p padding-l30">return (foldFeature != null) && (foldFeature.getState() == FoldingFeature.State.HALF_OPENED) && (foldFeature.getOrientation() == FoldingFeature.Orientation.VERTICAL);</p>
      <p class="p">}</p>

      <p class="p">private boolean isSeparating(FoldingFeature foldFeature) {</p>
        <p class="p padding-l30"> return (foldFeature != null) && (foldFeature.getState() == FoldingFeature.State.FLAT) && (foldFeature.isSeparating() == true);</p>
      <p class="p">}</p>

    </div>
    <p class="p margin-b10 padding-l30">
      For details, please refer to the Android Developers’ Guide for
      <a
        class="a"
        href="https://developer.android.com/guide/topics/large-screens/make-apps-fold-aware"
      >
        <i class="el-icon-paperclip"></i>
        Enabling the application to have fold sensing ability.
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-5">
      4.5 Activity Embedding
    </h3>
    <p class="p margin-b10 padding-l30">
      Large screen devices enable users to view more content, perform more operations, and experience more functions. Large display provides the opportunity to run multiple Activities or multiple instances of same activity at the same time. In order to utilize additional display area of large screen, Android 12L uses Jetpack WindowManager to introduce Activity Embedding. This function can split task window of application between Activities.
    </p>
    <p class="p margin-b10 padding-l30">
      Different from previous system level, for screen splitting between different applications, Activity Embedding only requires little application reconfiguration or no application reconfiguration is needed. You can determine how applications display their Activities (side by side or stacked) by creating XML configuration file or calling Jetpack WindowManager API. The system will automatically maintain support for small screens. When the application runs in a device with a small screen, the Activities will stack with each other while running in a device with large screen, the Activities are displayed side by side. The system will determine rendering mode according to the configuration you have created (no branching logic is required).
    </p>
    <p class="p margin-b10 padding-l30">
      Activity Embedding supports the change of device screen orientation, and can operate seamlessly on foldable devices. This function can stack and un-stack Activities as devices fold and expand. If the application consists of multiple Activities, Activity Embedding can easily provide an enhanced user experience on tablets, foldable devices and Chrome OS devices.
    </p>
    <p class="p margin-b10 padding-l30">
      For adaptation, please refer to the document: 
      <a
        class="a"
        href="/documentsBase/activityAdapterFile"
      >
        ITGSA Instructions for Activity Embedding Adaptation
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-6">
      4.6 WebView page screen adaptation
    </h3>
    <p class="p margin-b10 padding-l30">
      Responsive Web design is the mainstream method of H5 page design for implementing interface display and layout, but there are still problems unsolved. If window changes of foldable screen are not reasonably analyzed, following interface display problems may exist in flat state:
    </p>
    <p class="p margin-b10 padding-l30">
      ● The interface elements are magnified proportionally, and display effect is poor: such as too large/ fuzzy font, too large /fuzzy picture, etc.
    </p>
    <p class="p margin-b10 padding-l30">
      ● The interface elements are extended outside the interface area and cannot be operated: for example, the Close button of advertising box is displayed outside the interface area and cannot be closed.
    </p>
    <p class="p margin-b10 padding-l30">
      ● Overlapping and misplacement of interface content: for example, display of text in input box is truncated.
    </p>
    <p class="p margin-b10 padding-l30">
      To avoid above-mentioned basic experience problems, problem interface needs to be adjusted.
    </p>
    <p class="p margin-b10 padding-l30">
      For adaptation, please refer to the document: 
      <a
        class="a"
        href="/documentsBase/webviewAdapterFile"
      >
        ITGSA Instructions for Webview Adaptation
      </a>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="4-7">
      4.7 FAQ
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-1">
      4.7.1 How to adapt screen hole?
    </h4>
    <p class="p margin-b10 padding-l30">
      At present, most mainstream mobile phones have hole on their screens. Applications need to avoid the hole area to display content.
    </p>
    <p class="p margin-b10 padding-l30">
      For adaptive hole on their screen, please refer to the Android Developers' Guide for 
      <a
        class="a"
        href="https://developer.android.com/guide/topics/display-cutout"
      >
        <i class="el-icon-paperclip"></i>
         supporting Bang screen
      </a>
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-2">
      4.7.2 How to identify a manufacturer's foldable-screen device?
    </h4>
    <p class="p margin-b10 padding-l30">
      <b>It is recommended that applications adapt windows of different sizes and ratios by supporting “resizable”. It is strongly not recommended to implement special processing and UI adaptation by identifying foldable screen.</b>
    </p>
    <p class="p margin-b10 padding-l30">
      If third-party application needs to identify foldable-screen device of manufacturers, it can be identified with following methods.
    </p>
    <p class="p margin-b10 padding-l30">
      <b>Be careful!!! If it is unnecessary, please avoid special processing for specific device state.</b>
    </p>
    <p class="p margin-b10 padding-l30">
      OPPO：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public static boolean isOPPOFold() {</p>
        <p class="p padding-l30">
          boolean isFold = false;
        </p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> cls = Class.forName("com.oplus.content.OplusFeatureConfigManager");</p>
          <p class="p padding-l60"> Method instance = cls.getMethod("getInstance");</p>
          <p class="p padding-l60"> Object configManager = instance.invoke(null);</p>
          <p class="p padding-l60"> Method hasFeature = cls.getDeclaredMethod("hasFeature", String.class);</p>
          <p class="p padding-l60"> Object object = hasFeature.invoke(configManager, "oplus.hardware.type.fold");</p>
          <p class="p padding-l60"> if (object instanceof Boolean) {</p>
            <p class="p padding-l90"> isFold = (boolean) object;</p>
          <p class="p padding-l60"> }</p>
        <p class="p padding-l30"> } catch (ClassNotFoundException | NoSuchMethodException | InvocationTargetException | IllegalAccessException e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return isFold;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      vivo：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">private static boolean isVivoFoldableDevice(){</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> c= Class.forName("android.util.FtDeviceInfo");</p>
          <p class="p padding-l60"> Method  m = c.getMethod("getDeviceType");</p>
          <p class="p padding-l60"> Object dType = m.invoke(c);</p>
          <p class="p padding-l60"> return "foldable".equals(dType);</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      Xiaomi：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public static boolean isXiaomiFold() {</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class c = Class.forName("android.os.SystemProperties");</p>
          <p class="p padding-l60"> Method m = c.getMethod("getInt",String.class, int.class);</p>
          <p class="p padding-l60"> int type = (int) m.invoke(c,"persist.sys.muiltdisplay_type", 0);</p>
          <p class="p padding-l60"> return type == 2;</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      For adaptation, please refer to Section 4.1  
      <a  class="a" href="#4-1">
        (Screen Compatibility)
      </a>
      and Section 4.2 .
      <a  class="a" href="#4-2">
        (Application Continuity)
      </a>
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-3">
      4.7.3 How to identify a manufacturer's tablet device?
    </h4>
    <p class="p margin-b10 padding-l30">
      Xiaomi：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">public static boolean isXiaomiTablet() {</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class c = Class.forName("android.os.SystemProperties");</p>
          <p class="p padding-l60">  Method m = c.getMethod("get",String.class);</p>
          <p class="p padding-l60"> String type = String.valueOf(m.invoke(c,"ro.build.characteristics"));</p>
          <p class="p padding-l60"> return type.contains("tablet");</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    <p class="p margin-b10 padding-l30">
      vivo：
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">private static boolean isVivoTablet(){</p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> c= Class.forName("android.util.FtDeviceInfo");</p>
          <p class="p padding-l60"> Method  m = c.getMethod("getDeviceType");</p>
          <p class="p padding-l60"> Object dType = m.invoke(c);</p>
          <p class="p padding-l60"> return "tablet".equals(dType);</p>
        <p class="p padding-l30">} catch (Exception e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return false;</p>
      <p class="p ">}</p>
    </div>
    
    <p class="p margin-b10 padding-l30">
      OPPO：
    </p>
    <div class="code margin-b20  margin-l30">
      <p class="p ">public static boolean isOPPOTablet() {</p>
        <p class="p padding-l30">
          boolean isTablet = false;
        </p>
        <p class="p padding-l30">
           try {
        </p>
          <p class="p padding-l60"> Class&lt;?> cls = Class.forName("com.oplus.content.OplusFeatureConfigManager");</p>
          <p class="p padding-l60"> Method instance = cls.getMethod("getInstance");</p>
          <p class="p padding-l60"> Object configManager = instance.invoke(null);</p>
          <p class="p padding-l60"> Method hasFeature = cls.getDeclaredMethod("hasFeature", String.class);</p>
          <p class="p padding-l60"> Object object = hasFeature.invoke(configManager, "oplus.hardware.type.tablet");</p>
          <p class="p padding-l60"> if (object instanceof Boolean) {</p>
            <p class="p padding-l90">  isTablet = (boolean) object;</p>
          <p class="p padding-l60"> }</p>
        <p class="p padding-l30"> } catch (ClassNotFoundException | NoSuchMethodException | InvocationTargetException | IllegalAccessException e) {</p>
          <p class="p padding-l60"> e.printStackTrace();</p>
        <p class="p padding-l30">}</p>
        <p class="p padding-l30">return isTablet;</p>
      <p class="p ">}</p>
    </div>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-4">
      4.7.4 How to identify hover state of foldable screen?
    </h4>
    <p class="p margin-b10 padding-l30">
      Hover state is actually a half-opened state of screen, still in large screen mode. Vivo (starting from Android 12L), OPPO and Xiaomi (starting from Android T) support Google's JetPack to sense the flat state, half-opened state and half-opened state area. It is used for application to Listen half-opened state for hover layout.
    </p>
    <p class="p margin-b10 padding-l30">
      Please refer to Section 4.4.4 
      <a  class="a" href="#4-4-4">
      《Foldable screen state listen and acquisition》
      </a>
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-5">
      4.7.5 How to perceive hover state of foldable screen when the APP is not connected to Android X library?
    </h4>
    <p class="p margin-b10 padding-l30">
      Please refer to the document " 
      <a
        class="a"
        href="/documentsBase/androidXOnHoverFile"
      >
        ITGSA Instructions for Hover Listening with android T/Without androidX 
      </a>
      "
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="4-7-6">
      4.7.6  How to be compatible with both Activity Embedding and parallel viewport developed by the manufacturer?
    </h4>
    <p class="p margin-b10 padding-l30">
      When the application accesses the Activity Embedding and parallel viewport developed by the manufacturer at the same time, the system will give priority to supporting native Activity Embedding according to the ROM version. If the ROM version does not support Activity Embedding, the system will support the self-developed parallel viewport.
    </p>
    <p class="p margin-b10 padding-l30">
      At this time, the application needs to add following ID in the manifest and specify it as “false”.
    </p>
    <div class="code margin-b10  margin-l30">
      <p class="p ">&lt;application></p>
        <p class="p padding-l30">
          &lt;property android:name="android.window.PROPERTY_ACTIVITY_EMBEDDING_ALLOW_SYSTEM_OVERRIDE" android:value="false" />
        </p>
      <p class="p ">&lt;/application></p>
    </div>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="5">
      V. Development and debugging tools
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-1">
      5.1 Debugging with simulator
    </h3>
    <p class="p margin-b10 padding-l30">
      At present, Android Studio offers virtual device support for special-shaped screens. Pre-set virtual devices can be directly used for debugging. Now, Android Studio provides presets in the form of left-right inward folding, left-right outward folding, rollable, and up-down folding. They support dynamic switching states (resolution cannot be customized at present).
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image84.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image84.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      Starting virtual device, it can be seen that the toolbar of special-shaped screen device provides functions such as screen shape switching and device orientation rotation. This method can be used to dynamically debugging various states of application in special-shaped screen device (screen rotation, screen size switching, half folding).
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/whiteBookFile/image85.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image85.png',
        ]"
      >
      </el-image>
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-2">
      5.2 Separate debugging for different resolutions
    </h3>
    <p class="p margin-b10 padding-l30">
      <b>It is worth noting that special-shaped screen devices with different resolutions should still be debugged with device with customized resolution to ensure actual display effect.</b>
    </p>
    <p class="p margin-b10 padding-l30">
      Assuming that screen density ratio dpi of new modality project device is 440 and resolution is 1920x1700, developers can create screens of similar size through simulator for debugging and development, or use a large screen device to specify resolution and density through command.
    </p>
    <p class="p margin-b10 padding-l30">
      App developers can use command to simulate resolution and density:
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm size 1920x1700      //尺寸切换到1920x1700
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm density 440            //density切换到440
    </p>
    <p class="p margin-b10 padding-l30">
      Reset command:
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm size reset
    </p>
    <p class="p margin-b10 padding-l30">
      adb shell wm density reset
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-3">
      5.3 Suggestions for test instances
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-1">
      5.3.1  Instance 1
    </h4>
    <p class="p margin-b10 padding-l30">
      The core functions of pages of the App are normal in new modality item.
    </p>
    <p class="p margin-b10 padding-l30">
      Test steps:
    </p>
    <p class="p margin-b10 padding-l30">
      1）In new modality item, open the App to view the display effect of each page of application in landscape screen and portrait screen scenarios.
    </p>
    <p class="p margin-b10 padding-l30">
      Expected results:
    </p>
    <p class="p margin-b10 padding-l30">
      1）All pages of the App can be displayed in full screen, and the pages have no problems such as truncation, stretching and deformation, blurred magnification, missing buttons, etc. 
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-2">
      5.3.2 Instance 2
    </h4>
    <p class="p margin-b10 padding-l30">
      The core functions of pages of the App are normal in new modality item.
    </p>
    <p class="p margin-b10 padding-l30">
      Test steps:
    </p>
    <p class="p margin-b10 padding-l30">
      1）Open the App in new modality item;
    </p>
    <p class="p margin-b10 padding-l30">
      2）Traverse control buttons of the App’s core pages in landscape screen and portrait screen scenarios.
    </p>
    <p class="p margin-b10 padding-l30">
      Expected results:
    </p>
    <p class="p margin-b10 padding-l30">
      1） In new modality screen, all function buttons work normally, having no problems such as disabled/crash/anr.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-3">
      5.3.3 Instance 3
    </h4>
    <p class="p margin-b10 padding-l30">
      The display of the App’s core pages is normal when resolution changes.
    </p>
    <p class="p margin-b10 padding-l30">
      Test steps:
    </p>
    <p class="p margin-b10 padding-l30">
      1）Open the App in new modality item, then use command to switch to a different resolution of straight-screen device, such as 1080x1920
    </p>
    <p class="p margin-b10 padding-l30">
      Expected results:
    </p>
    <p class="p margin-b10 padding-l30">
      1）All pages of the App can be displayed in full screen, and the pages have no problems such as truncation, stretching and deformation, blurred magnification, missing buttons, etc.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-4">
      5.3.4 Instance 4
    </h4>
    <p class="p margin-b10 padding-l30">
      The function of the App’s core pages is normal when resolution changes.
    </p>
    <p class="p margin-b10 padding-l30">
      Test steps:
    </p>
    <p class="p margin-b10 padding-l30">
      1）Open the App in new modality item, then use command to switch to a different resolution of straight-screen device, such as 1080x1920
    </p>
    <p class="p margin-b10 padding-l30">
      2）Traverse control buttons of the App’s core pages.
    </p>
    <p class="p margin-b10 padding-l30">
      Expected results:
    </p>
    <p class="p margin-b10 padding-l30">
      1） After resolution is switched, all function buttons work normally, having no problems such as disabled/crash/anr.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="5-3-5">
      5.3.5  Instance 5
    </h4>
    <p class="p margin-b10 padding-l30">
      After entering small window and split screen mode of the App, the interface display and function interaction of the App are normal.
    </p>
    <p class="p margin-b10 padding-l30">
      Test steps:
    </p>
    <p class="p margin-b10 padding-l30">
      1）Enter the small window or split screen mode of the App.
    </p>
    <p class="p margin-b10 padding-l30">
      2）Observe whether the page display is normal.
    </p>
    <p class="p margin-b10 padding-l30">
      3）Click the buttons on each page in turn to observe whether function interaction is normal.
    </p>
    <p class="p margin-b10 padding-l30">
      Expected results:
    </p>
    <p class="p margin-b10 padding-l30">
      1）In small window and split screen mode, there are no such problems as page deformation, truncation, blurring, missing, etc.
    </p>
    <p class="p margin-b10 padding-l30">
      2）All page buttons of the App can respond normally, having no problems such as disabled/crash/anr.
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="6">
      VI. Appendix
    </h2>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="6-1">
      6.1  Introduction to manufacturers' product specifications
    </h3>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="6-1-1">
      6.1.1 Foldable mobile phone
    </h4>
    <p class="p margin-b10 padding-l30">
      OPPO Find N：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image86.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image86.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      Primary screen: 7.1 inch, 1792 × 1920 pixels, 8.4:9
    </p>
    <p class="p margin-b10 padding-l30">
      Secondary screen: 5.49 inch, 1972 × 988 pixels, 18:9
    </p>
    <p class="p margin-b30 padding-l30">
      density: 440
    </p>
    <p class="p margin-b10 padding-l30">
      vivo X Fold:
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image87.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image87.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      Primary screen: 8.03 inch, 2160 × 1916 pixels, 4:3.55
    </p>
    <p class="p margin-b10 padding-l30">
      Secondary screen: 6.53 inch, 2520 × 1080 pixels, 21:9
    </p>
    <p class="p margin-b30 padding-l30">
      density: 480
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi MIX FOLD：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image88.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image88.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      Primary screen: 8.01 inch, 2480 × 1860 pixels, 4:3
    </p>
    <p class="p margin-b10 padding-l30">
      Secondary screen: 6.52 inch, 2520 × 840 pixels, 27:9
    </p>
    <p class="p margin-b20 padding-l30">
      density: 440
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi MIX Fold 2：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image89.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image89.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      Primary screen: 8.02 inch, 2160 × 1914，4:3.55
    </p>
    <p class="p margin-b10 padding-l30">
      Secondary screen: 6.56 inch, 2520 × 1080，21:9
    </p>
    <p class="p margin-b20 padding-l30">
      density: 440
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="6-1-2">
      6.1.2 Tablet
    </h4>
    <p class="p margin-b10 padding-l30">
      Xiaomi Pad
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image90.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image90.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi Pad 5: 11 inch, 2560x1600, 16:10, density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi Pad 5 Pro: 11 inch, 2560x1600, 16:10, density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi Pad 5 Pro 5G: 11 inch, 2560x1600, 16:10, density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      Xiaomi Pad 5 Pro 12.4: 12.4 inch, 2560x1600, 16:10, density: 320
    </p>
    <p class="p margin-t30 margin-b10 padding-l30">
      OPPO Pad:
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image91.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image91.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      OPPO Pad: 11 inch，2560 × 1600，16:10，density: 360
    </p>
    <p class="p margin-b10 padding-l30">
      OPPO Pad Air: 10.36 inch，2000×1200, 5:3，density：280
    </p>
    <p class="p margin-t30 margin-b10 padding-l30">
      vivo Pad：
    </p>
    <p class="margin-b20 padding-l30">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/whiteBookFile/image92.png'"
        :preview-src-list="[
          configBaseIMG+'/whiteBookFile/image92.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b10 padding-l30">
      vivo Pad: 11 inch，2560 × 1600，16:10，density: 360
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="6-2">
      6.2 Manufacturer adaptation guidance
    </h3>
    <p class="p  padding-l30">
      OPPO Adaptation Guide:
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://open.oppomobile.com/new/developmentDoc/info?id=11308" target="_blank">
        https://open.oppomobile.com/new/developmentDoc/info?id=11308
      </a>
    </p>
    <p class="p  padding-l30">
      Vivo Adaptation Guide:
    </p>
    <p class="p padding-l30">
      <a class="a"  href="https://dev.vivo.com.cn/documentCenter/doc/597" target="_blank">
        https://dev.vivo.com.cn/documentCenter/doc/597
      </a>
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://dev.vivo.com.cn/documentCenter/doc/616" target="_blank">
        https://dev.vivo.com.cn/documentCenter/doc/616
      </a>
    </p>
    <p class="p  padding-l30">
     Xiaomi Adaptation Guide:
    </p>
    <p class="p margin-b20 padding-l30">
      <a class="a"  href="https://dev.mi.com/console/doc/detail?pId=2768" target="_blank">
        https://dev.mi.com/console/doc/detail?pId=2768
      </a>
    </p>
    
    
  </div>
</template>

<script>
export default {
  props: ['scrollContainer'],
  data() {
    return {
    };
  },
  created() {
    
  },
};
</script>

<style scoped lang="scss">
.h1 {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p {
  display: block;
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
.a {
  color: rgb(64, 158, 255);
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>
